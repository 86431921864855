import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  Modal,
  FormControl,
  Tooltip,
  OverlayTrigger,
  ProgressBar,
  ListGroup,
  Badge,
  Form,
} from "react-bootstrap";
import {
  FaPlus,
  FaEdit,
  FaBookOpen,
  FaStar,
  FaRegClock,
  FaAward,
  FaFire,
} from "react-icons/fa";
import ReactApexChart from "react-apexcharts";
import Header from "../layouts/Header";
import { RiCheckboxCircleFill, RiCloseCircleFill } from "react-icons/ri";
import Footer from "../layouts/Footer";
import styles from "../assets/css/CourseDetail.module.css";

const CourseDetailsPage = () => {
  useEffect(() => {
    document.title = "Course Details | YouElite";
  }, []);

  const [notes, setNotes] = useState([]);
  const [tests, setTests] = useState([]);
  const [wrongQuestions, setWrongQuestions] = useState([]);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [showTestModal, setShowTestModal] = useState(false);
  const [showStudyPlanModal, setShowStudyPlanModal] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [courseProgress, setCourseProgress] = useState(70); // Placeholder progress
  const [mastery, setMastery] = useState(false); // Placeholder mastery status
  const [studyStreak, setStudyStreak] = useState(5); // Placeholder study streak
  const [newTag, setNewTag] = useState("");
  const [testType, setTestType] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [testDuration, setTestDuration] = useState(40);
  const [durationError, setDurationError] = useState("");
  const [examDate, setExamDate] = useState(null);
  const [selectedExamDate, setSelectedExamDate] = useState("");
  const [isChangingDate, setIsChangingDate] = useState(false);
  const [onTrack, setOnTrack] = useState(null);
  const [progressDetails, setProgressDetails] = useState(null);
  const [accuracyStreak, setAccuracyStreak] = useState(3);
  const [showAllNotes, setShowAllNotes] = useState(false);

  const handleDurationChange = (e) => {
    const value = Number(e.target.value);

    if (value < 1) {
      setDurationError("Minimum duration is 1 minute.");
    } else if (value > 120) {
      setDurationError("Maximum duration is 120 minutes.");
    } else {
      setDurationError("");
    }

    setTestDuration(value);
  };

  // Placeholder data for notes, tests, and wrong questions
  useEffect(() => {
    const placeholderNotes = [
      {
        id: 1,
        title: "Thermodynamics Notes",
        tags: ["Thermodynamics"],
        date: "2023-10-01",
        processed: true,
      },
      {
        id: 2,
        title: "Chemical Bonding Overview",
        tags: ["Chemical Bonding"],
        date: "2023-09-25",
        processed: false,
      },
      {
        id: 3,
        title: "Chemical Bonding Overview",
        tags: ["Chemical Bonding"],
        date: "2023-09-25",
        processed: false,
      },
      {
        id: 4,
        title: "Chemical Bonding Overview",
        tags: ["Chemical Bonding"],
        date: "2023-09-25",
        processed: false,
      },
      {
        id: 5,
        title: "Chemical Bonding Overview",
        tags: ["Chemical Bonding"],
        date: "2023-09-25",
        processed: false,
      },
      {
        id: 6,
        title: "Chemical Bonding Overview",
        tags: ["Chemical Bonding"],
        date: "2023-09-25",
        processed: false,
      },
      // Add more notes as needed
    ];
    setNotes(placeholderNotes);

    const placeholderTests = [
      { id: 1, date: "2023-10-05", score: 85, totalQuestions: 20 },
      { id: 2, date: "2023-09-28", score: 90, totalQuestions: 30 },
      // Add more tests as needed
    ];
    setTests(placeholderTests);

    const placeholderWrongQuestions = [
      { tag: "Thermodynamics", count: 5 },
      { tag: "Organic Chemistry", count: 3 },
      { tag: "Equilibrium", count: 2 },
      // Add more tags as needed
    ];
    setWrongQuestions(placeholderWrongQuestions);
  }, []);

  useEffect(() => {
    if (examDate) {
      const today = new Date();
      const exam = new Date(examDate);
      const timeDiff = exam.getTime() - today.getTime();
      const daysUntilExam = timeDiff / (1000 * 3600 * 24);

      if (daysUntilExam <= 0) {
        // Exam date is today or in the past
        setOnTrack(null);
        setProgressDetails(null);
      } else {
        const totalContent = 100; // Assuming total content is 100%
        const currentProgress = courseProgress; // Using courseProgress state variable
        const remainingContent = totalContent - currentProgress;
        const expectedDailyProgress = remainingContent / daysUntilExam;

        // Placeholder logic to determine if on track
        const onTrack = expectedDailyProgress <= 5; // Adjust threshold as needed

        setOnTrack(onTrack);
        setProgressDetails({
          daysUntilExam,
          totalContent,
          currentProgress,
          expectedDailyProgress,
        });
      }
    }
  }, [examDate, courseProgress]);

  useEffect(() => {
    const allTags = notes.flatMap((note) => note.tags);
    const uniqueTags = [...new Set(allTags)];
    setCategories(uniqueTags);
  }, [notes]);

  const handleUploadNote = () => {
    setNotes([
      ...notes,
      {
        id: Date.now(),
        title: newNote,
        tags: [newTag],
        date: new Date().toISOString().split("T")[0],
      },
    ]);
    setNewNote("");
    setNewTag(""); // Reset tag field
    setShowUploadModal(false);
  };

  const renderNoteActions = (note) => (
    <div className={styles.noteActions}>
      <Button variant="link" size="sm">
        Edit Tags
      </Button>
      <Button variant="link" size="sm">
        Delete
      </Button>
    </div>
  );

  const renderTooltip = (props, content) => (
    <Tooltip id="button-tooltip" {...props}>
      {content}
    </Tooltip>
  );

  // Chart data for performance insights
  const performanceChartOptions = {
    chart: {
      type: "bar",
      height: 250,
      toolbar: {
        show: false, // Disable the toolbar (removes the burger menu)
      },
    },
    title: {
      text: "Topic Performance",
      align: "center",
    },
    xaxis: {
      categories: [
        "Thermodynamics",
        "Organic Chemistry",
        "Equilibrium",
        "Kinetics",
      ],
    },
    yaxis: {
      title: {
        text: "Score (%)",
      },
    },
  };

  const performanceChartSeries = [
    {
      name: "Score",
      data: [85, 60, 75, 90],
    },
  ];

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className={styles.contentWrapper}>
          <Row>
            {/* Left Section */}
            <Col md={8}>
              {/* Course Title and Quick Actions */}
              <div className="d-flex align-items-center mb-4">
                <h2 className="me-3">🧪 AP Chemistry</h2>
                {mastery && (
                  <OverlayTrigger
                    placement="right"
                    overlay={(props) => renderTooltip(props, "Mastered Course")}
                  >
                    <FaStar color="gold" size={24} />
                  </OverlayTrigger>
                )}
                {accuracyStreak > 0 && (
                  <div className={`${styles.streakBadge} ms-2`}>
                    <FaFire className="me-1" />
                    {accuracyStreak} Test Streak
                  </div>
                )}
              </div>

              <div className="mb-4">
                <Button
                  variant="primary"
                  className={`${styles.buttonIconText} me-2`}
                  onClick={() => setShowTestModal(true)}
                >
                  <FaEdit className="me-1" /> Start Test
                </Button>
                <Button
                  variant="secondary"
                  className={`${styles.buttonIconText} me-2`}
                  onClick={() => setShowUploadModal(true)}
                >
                  <FaPlus className="me-1" /> Add New Notes
                </Button>
                <Button
                  variant="secondary"
                  className={styles.buttonIconText}
                  onClick={() => setShowStudyPlanModal(true)}
                >
                  <FaBookOpen className="me-1" /> View Study Plan
                </Button>
              </div>

              {/* Notes Library */}
              <Card className={`mb-4 ${styles.customCard}`}>
                <Card.Header className={styles.customCardHeader}>
                  <Card.Title as="h5" className={styles.customCardTitle}>
                    Notes Library
                  </Card.Title>
                </Card.Header>
                <Card.Body className={styles.customCardBody}>
                  <ListGroup>
                    {(showAllNotes ? notes : notes.slice(0, 4)).map((note) => (
                      <ListGroup.Item
                        key={note.id}
                        className={styles.listGroupItem}
                      >
                        <div className="d-flex align-items-center">
                          {/* Status Icon */}
                          {note.processed ? (
                            <RiCheckboxCircleFill
                              color="green"
                              size={20}
                              className="me-2"
                            />
                          ) : (
                            <RiCloseCircleFill
                              color="red"
                              size={20}
                              className="me-2"
                            />
                          )}
                          <div className="flex-grow-1">
                            <h6 className="mb-1">{note.title}</h6>
                            <div>
                              {note.tags.map((tag, index) => (
                                <Badge
                                  bg="secondary"
                                  key={index}
                                  className={`me-1 ${styles.customBadge}`}
                                >
                                  {tag}
                                </Badge>
                              ))}
                            </div>
                          </div>
                          {renderNoteActions(note)}
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                  {notes.length > 4 && (
                    <Button
                      variant="link"
                      onClick={() => setShowAllNotes(!showAllNotes)}
                      className="mt-2"
                    >
                      {showAllNotes ? "Show Less" : "Show More"}
                    </Button>
                  )}
                </Card.Body>
              </Card>

              {/* Tests and Quizzes */}
              <Card className={`mb-4 ${styles.customCard}`}>
                <Card.Header className={styles.customCardHeader}>
                  <Card.Title as="h5" className={styles.customCardTitle}>
                    Tests and Quizzes
                  </Card.Title>
                </Card.Header>
                <Card.Body className={styles.customCardBody}>
                  <p className="mt-1">
                    Choose a test to check your mistakes and improve your
                    understanding of the topics!
                  </p>

                  {/* Past Test Results */}
                  <h6 className="mt-4">Past Test Results</h6>
                  <ListGroup>
                    {tests.map((test) => (
                      <ListGroup.Item
                        key={test.id}
                        className={styles.listGroupItem}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <p>
                              <strong>Date:</strong> {test.date}
                            </p>
                            <p>
                              <strong>Score:</strong> {test.score}% (
                              {test.totalQuestions} questions)
                            </p>
                          </div>
                          <Button variant="link" size="sm">
                            Review Test
                          </Button>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                </Card.Body>
              </Card>

              {/* Wrong Questions Review */}
              <Card className={`mb-4 ${styles.customCard}`}>
                <Card.Header className={styles.customCardHeader}>
                  <Card.Title as="h5" className={styles.customCardTitle}>
                    Wrong Questions Review
                  </Card.Title>
                </Card.Header>
                <Card.Body className={styles.customCardBody}>
                  <p>
                    Review your wrong answers organized by topic. You can start a
                    review test focusing on these areas.
                  </p>
                  <ListGroup>
                    {wrongQuestions.map((item, index) => (
                      <ListGroup.Item
                        key={index}
                        className={styles.listGroupItem}
                      >
                        <div className="d-flex justify-content-between align-items-center">
                          <span>{item.tag}</span>
                          <Badge bg="danger">{item.count} wrong answers</Badge>
                        </div>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                  <Button variant="primary" className="mt-3">
                    Start Review Test
                  </Button>
                </Card.Body>
              </Card>
            </Col>

            {/* Right Section */}
            <Col md={4}>
              {/* Course Summary & Insights */}
              <Card className={`mb-4 ${styles.customCard}`}>
                <Card.Header className={styles.customCardHeader}>
                  <Card.Title as="h5" className={styles.customCardTitle}>
                    Course Summary & Insights
                  </Card.Title>
                </Card.Header>
                <Card.Body className={styles.customCardBody}>
                  {/* Course Progress */}
                  <h6>Course Progress</h6>
                  <ProgressBar
                    now={courseProgress}
                    label={`${courseProgress}%`}
                    className="mb-3"
                  />
                  {mastery && (
                    <Badge bg="success" className="mb-3">
                      Mastered
                    </Badge>
                  )}

                  {/* Performance Insights */}
                  <h6>Performance Insights</h6>
                  <div style={{ height: 250 }}>
                    <ReactApexChart
                      options={performanceChartOptions}
                      series={performanceChartSeries}
                      type="bar"
                      height="100%"
                    />
                  </div>

                  {/* Suggested Study Focus */}
                  <h6 className="mt-4">Suggested Study Focus</h6>
                  <ul>
                    <li>Review notes on Organic Chemistry</li>
                    <li>Take a quiz on Reaction Mechanisms</li>
                  </ul>

                  {/* Achievements and Study Streaks */}
                  <h6 className="mt-4">Achievements</h6>
                  <p>
                    <FaAward className="me-1" /> Earned "Top Score" badge in
                    Thermodynamics
                  </p>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
        <Footer className={styles.footer} />
      </div>

      {/* Upload Notes Modal */}
      <Modal
        show={showUploadModal}
        onHide={() => setShowUploadModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload New Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormControl
            className="mb-3"
            placeholder="Note Title"
            value={newNote}
            onChange={(e) => setNewNote(e.target.value)}
          />
          <FormControl
            className="mb-3"
            placeholder="Tag (Category)"
            value={newTag}
            onChange={(e) => setNewTag(e.target.value)}
          />
          <Button variant="secondary" className="me-2">
            Upload File
          </Button>
          <Button variant="secondary">Upload Image</Button>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowUploadModal(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUploadNote}>
            Upload
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Start Test Modal */}
      <Modal
        show={showTestModal}
        onHide={() => setShowTestModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Start New Test</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Check
              type="radio"
              label="Timed Progressive Exam"
              name="testType"
              value="timed"
              checked={testType === "timed"}
              onChange={(e) => setTestType(e.target.value)}
              className="mb-2"
            />
            {testType === "timed" && (
              <Form.Group controlId="testDuration" className="mb-3">
                <Form.Label>Test Duration (minutes):</Form.Label>
                <Form.Control
                  type="number"
                  min="1"
                  max="120"
                  value={testDuration}
                  onChange={handleDurationChange}
                  isInvalid={!!durationError} // This makes the input rectangle show an error style
                />
                <Form.Control.Feedback type="invalid">
                  {durationError}
                </Form.Control.Feedback>
              </Form.Group>
            )}
            <Form.Check
              type="radio"
              label="Untimed Progressive Exam"
              name="testType"
              value="untimed"
              checked={testType === "untimed"}
              onChange={(e) => setTestType(e.target.value)}
              className="mb-2"
            />
            <Form.Check
              type="radio"
              label="Specific Sections"
              name="testType"
              value="specific"
              checked={testType === "specific"}
              onChange={(e) => setTestType(e.target.value)}
              className="mb-2"
            />

            {testType === "specific" && (
              <div className="mt-3">
                <h6>Select Sections:</h6>
                {categories.map((category, index) => (
                  <Form.Check
                    key={index}
                    type="checkbox"
                    label={category}
                    value={category}
                    checked={selectedCategories.includes(category)}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setSelectedCategories([...selectedCategories, category]);
                      } else {
                        setSelectedCategories(
                          selectedCategories.filter((c) => c !== category)
                        );
                      }
                    }}
                    className="mb-1"
                  />
                ))}
              </div>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowTestModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              // Handle starting the test with selected options
              console.log("Test Type:", testType);
              console.log("Test Duration:", testDuration);
              console.log("Selected Categories:", selectedCategories);
              setShowTestModal(false);
            }}
          >
            Start Test
          </Button>
        </Modal.Footer>
      </Modal>

      {/* View Study Plan Modal */}
      <Modal
        show={showStudyPlanModal}
        onHide={() => {
          setShowStudyPlanModal(false);
          setIsChangingDate(false);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Study Plan</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!examDate || isChangingDate ? (
            <Form>
              <Form.Group controlId="examDate">
                <Form.Label>Select your exam date:</Form.Label>
                <Form.Control
                  type="date"
                  value={selectedExamDate}
                  onChange={(e) => setSelectedExamDate(e.target.value)}
                  min={new Date().toISOString().split("T")[0]} // Prevent selecting past dates
                />
              </Form.Group>
            </Form>
          ) : (
            <>
              <div className="d-flex justify-content-between align-items-center mb-2">
                <p>Your exam date is set to: {examDate}</p>
                <Button
                  variant="link"
                  onClick={() => {
                    setIsChangingDate(true);
                    setSelectedExamDate(examDate);
                  }}
                >
                  Change Date
                </Button>
              </div>

              {onTrack !== null && progressDetails ? (
                <>
                  <h6>Progress Analytics</h6>
                  <p>
                    You have {Math.ceil(progressDetails.daysUntilExam)} days
                    until the exam.
                  </p>
                  <p>
                    You have completed {progressDetails.currentProgress}% of the
                    course content.
                  </p>
                  <p>
                    You need to complete an average of{" "}
                    {progressDetails.expectedDailyProgress.toFixed(2)}% per day
                    to finish before the exam.
                  </p>
                  {onTrack ? (
                    <p style={{ color: "green" }}>
                      You are on track to complete the content before the exam.
                    </p>
                  ) : (
                    <p style={{ color: "red" }}>
                      You need to increase your study pace to complete the
                      content before the exam.
                    </p>
                  )}
                </>
              ) : (
                <p>Unable to calculate progress analytics at this time.</p>
              )}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!examDate || isChangingDate ? (
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  setIsChangingDate(false);
                  setSelectedExamDate("");
                }}
              >
                Cancel
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  setExamDate(selectedExamDate);
                  setIsChangingDate(false);
                }}
              >
                Save Exam Date
              </Button>
            </>
          ) : (
            <Button
              variant="secondary"
              onClick={() => setShowStudyPlanModal(false)}
            >
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};

export default CourseDetailsPage;
