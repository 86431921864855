import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Button, Form, ListGroup, Badge } from 'react-bootstrap';
import Avatar from '../components/Avatar';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import axios from 'axios';

const PostDetailsPage = () => {
  const [post, setPost] = useState(null);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const { id } = useParams();  // Get the id from the URL

  useEffect(() => {
    fetchPostDetails();
  }, [id]);

  const fetchPostDetails = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get(`/posts/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setPost(response.data);
      setComments(response.data.comments);
    } catch (error) {
      console.error("Error fetching post details:", error);
    }
  };

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.post(`/posts/${id}/comments`, { content: newComment }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setComments([...comments, response.data]);
      setNewComment('');
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  if (!post) return <div>Loading...</div>;

  return (
    <div>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="content-wrapper">
          <Card className="post-details-card">
            <Card.Header>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <Avatar img={post.author.avatar} size="md" />
                  <div className="ms-3">
                    <h6 className="mb-0">{post.author.name}</h6>
                    <small className="text-muted">{post.created_at}</small>
                  </div>
                </div>
                <Badge bg="info">{post.type}</Badge>
              </div>
            </Card.Header>
            <Card.Body>
              <Card.Title>{post.title}</Card.Title>
              <Card.Text>{post.content}</Card.Text>
              <div className="d-flex justify-content-between align-items-center">
                <Button variant="outline-primary">
                  <i className="ri-thumb-up-line me-2"></i>Upvote ({post.upvotes})
                </Button>
                <Button variant="outline-secondary">
                  <i className="ri-share-forward-line me-2"></i>Share
                </Button>
              </div>
            </Card.Body>
          </Card>

          <Card className="mt-4">
            <Card.Header>
              <h5>Comments ({comments.length})</h5>
            </Card.Header>
            <ListGroup variant="flush">
              {comments.map(comment => (
                <ListGroup.Item key={comment.id}>
                  <div className="d-flex">
                    <Avatar img={comment.author.avatar} size="sm" />
                    <div className="ms-3">
                      <div className="fw-bold">{comment.author.name}</div>
                      {comment.content}
                      <div><small className="text-muted">{comment.created_at}</small></div>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
            <Card.Body>
              <Form onSubmit={handleCommentSubmit}>
                <Form.Group className="mb-3">
                  <Form.Control 
                    as="textarea" 
                    rows={3} 
                    placeholder="Write a comment..."
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                  />
                </Form.Group>
                <Button variant="primary" type="submit">Post Comment</Button>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PostDetailsPage;
