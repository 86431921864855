import React, { useState, useEffect } from 'react';
import { Alert, Table, Card, Form, Row, Col, Button, Dropdown, InputGroup, FormControl, Tooltip, OverlayTrigger } from 'react-bootstrap';
import axios from 'axios';
import Select from 'react-select';
import debounce from 'lodash.debounce';
import ManageSubscriptionButton from '../components/ManageSubscriptionButton';


const ProfileTab = () => {
    const [formData, setFormData] = useState({
        name: '',
        surname: '',
        address: '',
        city: '',
        state: '',
        zip_code: '',
        phone_number: '',
        bio: '',
        urm_status: '',
        citizenship_status: '',
        legal_sex: '',
        us_armed_forces_status: '',
        family_income: ''
    });

    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchUserData = async () => {
            const user = localStorage.getItem('user');
            if (!user) {
                setMessage('No authentication token found');
                setLoading(false);
                return;
            }

            const { token } = JSON.parse(user);

            try {
                const response = await axios.get('https://api.student.youelite.net/api/user', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                });
                const data = response.data;
                // Replace null or undefined values with empty strings
                for (let key in data) {
                    if (data[key] === null || data[key] === undefined) {
                        data[key] = '';
                    }
                }
                setFormData(data);
            } catch (error) {
                console.error('Failed to fetch user data:', error);
                setMessage('Failed to load profile data');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updateData = {};
        for (let key in formData) {
            if (formData[key]) {
                updateData[key] = formData[key];
            }
        }

        try {
            const user = localStorage.getItem('user');
            if (!user) {
                setMessage('No authentication token found');
                return;
            }

            const { token } = JSON.parse(user);

            await axios.put('https://api.student.youelite.net/api/user', updateData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });
            setMessage('Profile updated successfully');
        } catch (error) {
            console.error('Failed to update profile:', error);
            setMessage('Failed to update profile');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title>Profile Information</Card.Title>
                <Card.Text>Manage your profile information.</Card.Text>
            </Card.Header>
            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Col md="6">
                            <Form.Group controlId="name">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={formData.name || ''}
                                    onChange={handleChange}
                                    placeholder="Enter name"
                                />
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group controlId="surname">
                                <Form.Label>Surname</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="surname"
                                    value={formData.surname || ''}
                                    onChange={handleChange}
                                    placeholder="Enter surname"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="6">
                            <Form.Group controlId="address">
                                <Form.Label>Address</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="address"
                                    value={formData.address || ''}
                                    onChange={handleChange}
                                    placeholder="Enter address"
                                />
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group controlId="city">
                                <Form.Label>City</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="city"
                                    value={formData.city || ''}
                                    onChange={handleChange}
                                    placeholder="Enter city"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="6">
                            <Form.Group controlId="state">
                                <Form.Label>State</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="state"
                                    value={formData.state || ''}
                                    onChange={handleChange}
                                    placeholder="Enter state"
                                />
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group controlId="zip_code">
                                <Form.Label>Zip Code</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="zip_code"
                                    value={formData.zip_code || ''}
                                    onChange={handleChange}
                                    placeholder="Enter zip code"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="6">
                            <Form.Group controlId="phone_number">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="phone_number"
                                    value={formData.phone_number || ''}
                                    onChange={handleChange}
                                    placeholder="Enter phone number"
                                />
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group controlId="bio"> {/* Added Bio field */}
                                <Form.Label>Bio</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="bio"
                                    value={formData.bio || ''}
                                    onChange={handleChange}
                                    placeholder="Enter bio"
                                />
                            </Form.Group>
                        </Col>
                    </Row>                    
                    <Row className="mb-3">
                        <Col md="6">
                            <Form.Group controlId="urm_status">
                                <Form.Label>URM Status</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="urm_status"
                                    value={formData.urm_status || ''}
                                    onChange={handleChange}
                                >
                                    <option value="">Select URM status</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group controlId="citizenship_status">
                                <Form.Label>Citizenship Status</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="citizenship_status"
                                    value={formData.citizenship_status || ''}
                                    onChange={handleChange}
                                >
                                    <option value="">Select citizenship status</option>
                                    <option value="US Citizen/US national/US Green card holder">US Citizen/US national/US Green card holder</option>
                                    <option value="Citizen of a country that is not the USA / International">Citizen of a country that is not the USA / International</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="6">
                            <Form.Group controlId="legal_sex">
                                <Form.Label>Legal Sex</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="legal_sex"
                                    value={formData.legal_sex || ''}
                                    onChange={handleChange}
                                >
                                    <option value="">Select legal sex</option>
                                    <option value="M">M</option>
                                    <option value="F">F</option>
                                    <option value="X or another legal sex">X or another legal sex</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group controlId="us_armed_forces_status">
                                <Form.Label>US Armed Forces Status</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="us_armed_forces_status"
                                    value={formData.us_armed_forces_status || ''}
                                    onChange={handleChange}
                                >
                                    <option value="">Select US Armed Forces status</option>
                                    <option value="Currently serving">Currently serving</option>
                                    <option value="Previously served">Previously served</option>
                                    <option value="N/A">N/A</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="6">
                            <Form.Group controlId="family_income">
                                <Form.Label>Family Income</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="family_income"
                                    value={formData.family_income || ''}
                                    onChange={handleChange}
                                    placeholder="Enter family income"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button variant="primary" type="submit">Save Changes</Button>
                </Form>
                {message && <p>{message}</p>}
            </Card.Body>
        </Card>
    );
};





const EducationTab = () => {
    const [formData, setFormData] = useState({
        sat_score: '',
        act_cumulative_score: '',
        sat_math_score: '',
        sat_reading_writing_score: '',
        cumulative_gpa_unweighted: '',
        cumulative_gpa_weighted: '', // New field for weighted GPA
        class_rank: '',
        gpa_scale_report: '',
        has_honors: 'No',
        honors: [{ name: '', grade: '', level_of_recognition: '' }],
        rec_letters: '',
        hs_name: '' 
    });

    const [searchQuery, setSearchQuery] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);
    const [satWarning, setSatWarning] = useState(false);

    useEffect(() => {
        const fetchUserData = async () => {
            const user = localStorage.getItem('user');
            if (!user) {
                setMessage('No authentication token found');
                setLoading(false);
                return;
            }

            const { token } = JSON.parse(user);

            try {
                const [userResponse, honorsResponse] = await Promise.all([
                    axios.get('https://api.student.youelite.net/api/user', {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        }
                    }),
                    axios.get('https://api.student.youelite.net/api/honors', {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        }
                    })
                ]);

                const userData = userResponse.data;
                const honorsData = honorsResponse.data;

                setFormData((prevData) => ({
                    ...prevData,
                    ...userData,
                    hs_name: userData.hs_name || '', // Ensure hs_name is included
                    has_honors: honorsData.length > 0 ? 'Yes' : 'No',
                    honors: honorsData.length > 0 ? honorsData : [{ name: '', grade: '', level_of_recognition: '' }]
                }));
                setSearchQuery(userData.hs_name || ''); // Set the search query to the fetched hs_name
            } catch (error) {
                console.error('Failed to fetch data:', error);
                setMessage('Failed to load profile data');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            const updatedData = { ...prevData, [name]: value };
            if (name === 'has_honors' && value === 'No') {
                updatedData.honors = [{ name: '', grade: '', level_of_recognition: '' }];
            }

            if (name === 'sat_score') {
                setSatWarning(value === '');
            }
            return updatedData;
        });
    };



    const validateSatScore = debounce(() => {
        if (!formData.sat_score) {
            setSatWarning(true);
        }
    }, 300);


    const handleBlur = (e) => {
        if (e.target.name === 'sat_score') {
            validateSatScore();
        }
    };


    const handleHonorChange = (index, e) => {
        const { name, value } = e.target;
        const honors = [...formData.honors];
        honors[index][name] = value;
        setFormData((prevData) => ({ ...prevData, honors }));
    };

    const addHonor = () => {
        setFormData((prevData) => ({
            ...prevData,
            honors: [...prevData.honors, { name: '', grade: '', level_of_recognition: '' }]
        }));
    };

    const removeHonor = (index) => {
        const updatedHonors = formData.honors.filter((_, i) => i !== index);
        setFormData((prevData) => ({
            ...prevData,
            honors: updatedHonors,
            has_honors: updatedHonors.length === 0 ? 'No' : 'Yes'
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updateUserData = {};
        for (let key in formData) {
            if (key !== 'honors' && key !== 'has_honors') {
                if (key === 'gpa_scale_report') {
                    updateUserData[key] = formData[key] === 'None' || formData[key] === '' ? null : formData[key];
                } else {
                    updateUserData[key] = formData[key];
                }
            }
        }

        try {
            const user = localStorage.getItem('user');
            if (!user) {
                setMessage('No authentication token found');
                return;
            }

            const { token } = JSON.parse(user);

            const userUpdatePromise = axios.put('https://api.student.youelite.net/api/user', updateUserData, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                }
            });

            if (formData.has_honors === 'Yes') {
                const updateHonorsData = formData.honors.filter(honor => honor.name !== '');
                if (updateHonorsData.length > 0) {
                    const honorsUpdatePromise = axios.put('https://api.student.youelite.net/api/honors', { honors: updateHonorsData }, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        }
                    });
                    await Promise.all([userUpdatePromise, honorsUpdatePromise]);
                } else {
                    await Promise.all([userUpdatePromise, axios.delete('https://api.student.youelite.net/api/honors', {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        }
                    })]);
                }
            } else {
                await Promise.all([userUpdatePromise, axios.delete('https://api.student.youelite.net/api/honors', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                })]);
            }

            setMessage('Profile updated successfully');
        } catch (error) {
            console.error('Failed to update profile:', error);
            if (error.response) {
                console.error('Error response:', error.response.data);
                setMessage(`Failed to update profile: ${error.response.data.message || error.response.statusText}`);
            } else if (error.request) {
                console.error('Error request:', error.request);
                setMessage('Failed to update profile: No response received from server');
            } else {
                console.error('Error message:', error.message);
                setMessage(`Failed to update profile: ${error.message}`);
            }
        }
    };

    const fetchSuggestions = debounce(async (query) => {
        if (!query) {
            setSuggestions([]);
            return;
        }

        try {
            const response = await axios.get(`https://api.student.youelite.net/api/high-schools?search=${query}`);
            setSuggestions(response.data);
            console.log("Suggestions fetched:", response.data);  // Added for debugging
        } catch (error) {
            console.error('Failed to fetch suggestions:', error);
        }
    }, 300);

    const handleSearchChange = (e) => {
        const query = e.target.value;
        setSearchQuery(query);
        fetchSuggestions(query);
        setShowSuggestions(true);
    };

    const handleSearchBlur = () => {
        setTimeout(() => {
            setShowSuggestions(false);
        }, 200);
    };

    const handleSearchFocus = () => {
        if (suggestions.length > 0) {
            setShowSuggestions(true);
        }
    };

    const handleSelectSchool = (schoolName) => {
        setFormData((prevData) => ({ ...prevData, hs_name: schoolName }));
        setSearchQuery(schoolName);
        setShowSuggestions(false);
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title>Education Information</Card.Title>
                <Card.Text>Manage your education information.</Card.Text>
            </Card.Header>
            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Col md="6">
                            <Form.Group controlId="sat_score">
                                <Form.Label>SAT Score <span className="text-danger">*</span></Form.Label>
                                <OverlayTrigger
                                    placement="right"
                                    overlay={
                                        <Tooltip id="satTooltip">
                                            The SAT score is critical for most of the services. Please provide your best estimate if you haven't taken the test yet.
                                        </Tooltip>
                                    }
                                >
                                    <Form.Control
                                        type="number"
                                        name="sat_score"
                                        value={formData.sat_score || ''}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Enter your SAT score or best guess"
                                        isInvalid={satWarning} // Add red border if warning is active
                                    />
                                </OverlayTrigger>
                                {satWarning && (
                                    <div className="text-danger mt-2">
                                        Your SAT score is essential for accurate analysis. Please enter a score or estimate.
                                    </div>
                                )}
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group controlId="act_cumulative_score">
                                <Form.Label>ACT Score</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="act_cumulative_score"
                                    value={formData.act_cumulative_score || ''}
                                    onChange={handleChange}
                                    placeholder="Enter ACT score"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="6">
                            <Form.Group controlId="sat_math_score">
                                <Form.Label>SAT Math Score</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="sat_math_score"
                                    value={formData.sat_math_score || ''}
                                    onChange={handleChange}
                                    placeholder="Enter SAT math score"
                                />
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group controlId="sat_reading_writing_score">
                                <Form.Label>SAT English Score</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="sat_reading_writing_score"
                                    value={formData.sat_reading_writing_score || ''}
                                    onChange={handleChange}
                                    placeholder="Enter SAT english score"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="6">
                            <Form.Group controlId="cumulative_gpa_unweighted">
                                <Form.Label>Unweighted GPA</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="cumulative_gpa_unweighted"
                                    value={formData.cumulative_gpa_unweighted || ''}
                                    onChange={handleChange}
                                    placeholder="Enter GPA"
                                />
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group controlId="cumulative_gpa_weighted">
                                <Form.Label>Weighted GPA</Form.Label> {/* New field added */}
                                <Form.Control
                                    type="text"
                                    name="cumulative_gpa_weighted"
                                    value={formData.cumulative_gpa_weighted || ''}
                                    onChange={handleChange}
                                    placeholder="Enter Weighted GPA"
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="6">
                            <Form.Group controlId="class_rank">
                                <Form.Label>Class Rank</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="class_rank"
                                    value={formData.class_rank || ''}
                                    onChange={handleChange}
                                    placeholder="Enter class rank"
                                />
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group controlId="gpa_scale_report">
                                <Form.Label>GPA Grading System</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="gpa_scale_report"
                                    value={formData.gpa_scale_report || ''}
                                    onChange={handleChange}
                                >
                                    <option value="">Select GPA grading system</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="6">6</option>
                                    <option value="7">7</option>
                                    <option value="8">8</option>
                                    <option value="9">9</option>
                                    <option value="10">10</option>
                                    <option value="11">11</option>
                                    <option value="12">12</option>
                                    <option value="13">13</option>
                                    <option value="14">14</option>
                                    <option value="15">15</option>
                                    <option value="16">16</option>
                                    <option value="17">17</option>
                                    <option value="18">18</option>
                                    <option value="19">19</option>
                                    <option value="20">20</option>
                                    <option value="100">100</option>
                                    <option value="None">None</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="6">
                            <Form.Group controlId="rec_letters">
                                <Form.Label>Strength of Recommendation Letters (5 = highest)</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="rec_letters"
                                    value={formData.rec_letters || ''}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Strength</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col md="6">
                            <Form.Group controlId="hs_name">
                                <Form.Label>High School Name</Form.Label>
                                <InputGroup>
                                    <FormControl
                                        type="text"
                                        placeholder="Enter your high school name"
                                        name="hs_name"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                        onBlur={handleSearchBlur}
                                        onFocus={handleSearchFocus}
                                    />
                                    {showSuggestions && suggestions.length > 0 && (
                                        <Dropdown.Menu show style={{
                                            display: 'block',
                                            width: '100%',
                                            maxHeight: '300px',
                                            overflowY: 'auto',
                                            zIndex: 1000,
                                            position: 'absolute',
                                            top: '100%',
                                            left: 0,
                                        }}>
                                            {suggestions.map((suggestion, index) => (
                                                <Dropdown.Item key={index} onClick={() => handleSelectSchool(suggestion.hs_name)}>
                                                    {suggestion.hs_name}
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    )}
                                </InputGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="12">
                            <Form.Group controlId="has_honors">
                                <Form.Label>Do you have honors to report? (max. 5)</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="has_honors"
                                    value={formData.has_honors}
                                    onChange={(e) => {
                                        handleChange(e);
                                        if (e.target.value === 'No') {
                                            setFormData((prevData) => ({
                                                ...prevData,
                                                honors: [{ name: '', grade: '', level_of_recognition: '' }]
                                            }));
                                        }
                                    }}
                                >
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    {formData.has_honors === 'Yes' && formData.honors && formData.honors.map((honor, index) => (
                        <Row className="mb-3 align-items-end" key={index}>
                            <Col md="5">
                                <Form.Group controlId={`honor_name_${index}`}>
                                    <Form.Label>Honor {index + 1} Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={honor.name || ''}
                                        onChange={(e) => handleHonorChange(index, e)}
                                        placeholder="Enter honor name"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md="2">
                                <Form.Group controlId={`honor_grade_${index}`}>
                                    <Form.Label>Grade</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="grade"
                                        value={honor.grade || ''}
                                        onChange={(e) => handleHonorChange(index, e)}
                                    >
                                        <option value="">Select grade</option>
                                        <option value="9">9</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="PG">PG</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md="3">
                                <Form.Group controlId={`honor_level_of_recognition_${index}`}>
                                    <Form.Label>Level</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="level_of_recognition"
                                        value={honor.level_of_recognition || ''}
                                        onChange={(e) => handleHonorChange(index, e)}
                                    >
                                        <option value="">Select level</option>
                                        <option value="School">School</option>
                                        <option value="State/regional">State/regional</option>
                                        <option value="National">National</option>
                                        <option value="International">International</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md="2" className="d-flex align-items-center justify-content-center">
                                <Button
                                    variant="danger"
                                    onClick={() => removeHonor(index)}
                                >
                                    Remove
                                </Button>
                            </Col>
                        </Row>
                    ))}
                    {formData.has_honors === 'Yes' && formData.honors && formData.honors.length < 5 && (
                        <Row className="mb-3">
                            <Col className="text-end">
                                <Button variant="primary" onClick={addHonor}>
                                    Add Another Honor
                                </Button>
                            </Col>
                        </Row>
                    )}
                    <Button variant="primary" type="submit" className="me-2">Save Changes</Button>
                </Form>
                {message && <p>{message}</p>}
            </Card.Body>
        </Card>
    );
    
};







const SettingsTab = () => {
    const [formData, setFormData] = useState({
        emailNotifications: false,
        educationPrivacy: false,
        profilePrivacy: false,
        listPrivacy: false,
        focus: ''
    });
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);

    const focusOptions = [
        { value: 0, label: 'Balanced' },
        { value: 1, label: 'Academic Focus' },
        { value: 2, label: 'Financial Focus' },
        { value: 3, label: 'Career Focus' },
        { value: 4, label: 'Social Focus' }
    ];

    useEffect(() => {
        const fetchUserData = async () => {
            const user = localStorage.getItem('user');
            if (!user) {
                setMessage('No authentication token found');
                setLoading(false);
                return;
            }

            const { token } = JSON.parse(user);

            try {
                const [userResponse, focusResponse] = await Promise.all([
                    axios.get('https://api.student.youelite.net/api/user', {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        }
                    }),
                    axios.get('https://api.student.youelite.net/api/user/focus', {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        }
                    })
                ]);
                
                setFormData({
                    emailNotifications: userResponse.data.email_notifications === 1,
                    educationPrivacy: userResponse.data.edu_privacy === 1,
                    profilePrivacy: userResponse.data.profile_privacy === 1,
                    listPrivacy: userResponse.data.list_privacy === 1,
                    focus: focusResponse.data.focus.toString()
                });
            } catch (error) {
                console.error('Failed to fetch user data:', error);
                setMessage('Failed to load settings data');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const user = localStorage.getItem('user');
            if (!user) {
                setMessage('No authentication token found');
                return;
            }

            const { token } = JSON.parse(user);

            await Promise.all([
                axios.put('https://api.student.youelite.net/api/user', 
                    {
                        email_notifications: formData.emailNotifications ? 1 : 0,
                        edu_privacy: formData.educationPrivacy ? 1 : 0,
                        profile_privacy: formData.profilePrivacy ? 1 : 0,
                        list_privacy: formData.listPrivacy ? 1 : 0,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        }
                    }
                ),
                axios.put('https://api.student.youelite.net/api/user/focus', 
                    { focus: parseInt(formData.focus) },
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'Content-Type': 'application/json',
                        }
                    }
                )
            ]);

            setMessage('Settings updated successfully');
        } catch (error) {
            console.error('Failed to update settings:', error);
            setMessage('Failed to update settings');
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title>User Settings</Card.Title>
                <Card.Text>Manage your account settings.</Card.Text>
            </Card.Header>
            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Col md="12">
                            <Form.Group controlId="emailNotifications">
                                <Form.Check 
                                    type="switch" 
                                    label="Receive email notifications" 
                                    name="emailNotifications"
                                    checked={formData.emailNotifications}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="12">
                            <Form.Group controlId="educationPrivacy">
                                <Form.Check 
                                    type="switch" 
                                    label="Show education information in your profile page" 
                                    name="educationPrivacy"
                                    checked={formData.educationPrivacy}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="12">
                            <Form.Group controlId="profilePrivacy">
                                <Form.Check 
                                    type="switch" 
                                    label="Show your profile information in your profile page" 
                                    name="profilePrivacy"
                                    checked={formData.profilePrivacy}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="12">
                            <Form.Group controlId="listPrivacy">
                                <Form.Check 
                                    type="switch" 
                                    label="Show your college list in your profile page" 
                                    name="listPrivacy"
                                    checked={formData.listPrivacy}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="12">
                            <Form.Group controlId="processPrivacy">
                                <Form.Check 
                                    type="switch" 
                                    label="Show your college application status in your profile page" 
                                    name="processPrivacy"
                                    checked={formData.processPrivacy}
                                    onChange={handleChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col md="12">
                            <Form.Group controlId="focus">
                                <Form.Label>College Search Focus</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="focus"
                                    value={formData.focus}
                                    onChange={handleChange}
                                >
                                    <option value="">Select focus</option>
                                    {focusOptions.map(option => (
                                        <option key={option.value} value={option.value}>
                                            {option.label}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button variant="primary" type="submit">Save Changes</Button>
                </Form>
                {message && <p className="mt-3">{message}</p>}
            </Card.Body>
        </Card>
    );
};




const ActivitiesTab = () => {
    const [formData, setFormData] = useState({
        has_activities: 'No',
        activities: [{ id: null, name: '', strength: null, description: '' }] // Initialize strength as null (single value)
    });

    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(true);

    const numberOptions = [
        { value: 1, label: '1' },
        { value: 2, label: '2' },
        { value: 3, label: '3' },
        { value: 4, label: '4' },
        { value: 5, label: '5' }
    ];

    useEffect(() => {
        const fetchUserData = async () => {
            const user = localStorage.getItem('user');
            if (!user) {
                setMessage('No authentication token found');
                setLoading(false);
                return;
            }

            const { token } = JSON.parse(user);

            try {
                const response = await axios.get('https://api.student.youelite.net/api/activities', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                });

                const activitiesData = response.data;

                setFormData({
                    has_activities: activitiesData.length > 0 ? 'Yes' : 'No',
                    activities: activitiesData.length > 0 ? activitiesData.map(activity => ({
                        ...activity,
                        strength: Number(activity.strength) || null // Ensure strength is a number
                    })) : [{ id: null, name: '', strength: null, description: '' }]
                });
            } catch (error) {
                console.error('Failed to fetch activities data:', error);
                setMessage('Failed to load activities data');
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => {
            const updatedData = { ...prevData, [name]: value };
            if (name === 'has_activities' && value === 'No') {
                updatedData.activities = [{ id: null, name: '', strength: null, description: '' }];
            }
            return updatedData;
        });
    };

    const handleActivityChange = (index, e) => {
        const { name, value } = e.target;
        const activities = [...formData.activities];
        activities[index][name] = value;
        setFormData((prevData) => ({ ...prevData, activities }));
    };

    const handleStrengthChange = (index, selectedOption) => {
        const activities = [...formData.activities];
        activities[index].strength = selectedOption ? selectedOption.value : null;
        setFormData((prevData) => ({ ...prevData, activities }));
    };

    const addActivity = () => {
        setFormData((prevData) => ({
            ...prevData,
            activities: [...prevData.activities, { id: null, name: '', strength: null, description: '' }]
        }));
    };

    const removeActivity = async (index) => {
        const activityToDelete = formData.activities[index];
    
        // If the activity has an ID, it means it's saved on the backend, so we need to delete it there
        if (activityToDelete.id) {
            try {
                const user = localStorage.getItem('user');
                if (!user) {
                    setMessage('No authentication token found');
                    return;
                }
    
                const { token } = JSON.parse(user);
    
                await axios.delete(`https://api.student.youelite.net/api/activities/${activityToDelete.id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                });
    
                setMessage('Activity deleted successfully');
            } catch (error) {
                console.error('Failed to delete activity:', error);
                setMessage('Failed to delete activity');
                return;
            }
        }
    
        // Update local state to remove the activity
        const updatedActivities = formData.activities.filter((_, i) => i !== index);
        setFormData((prevData) => ({
            ...prevData,
            activities: updatedActivities,
            has_activities: updatedActivities.length === 0 ? 'No' : 'Yes'
        }));
    };
    

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const user = localStorage.getItem('user');
            if (!user) {
                setMessage('No authentication token found');
                return;
            }

            const { token } = JSON.parse(user);

            if (formData.has_activities === 'Yes') {
                const requests = formData.activities.map(activity => {
                    // Ensure that every activity has the required fields
                    if (!activity.name || activity.strength === null) {
                        throw new Error('Each activity must have a name and a strength.');
                    }

                    if (activity.id) {
                        // Update existing activity
                        return axios.put(`https://api.student.youelite.net/api/activities/${activity.id}`, activity, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            }
                        });
                    } else {
                        // Create new activity
                        return axios.post('https://api.student.youelite.net/api/activities', activity, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            }
                        });
                    }
                });

                await Promise.all(requests);
            } else {
                // If no activities exist, delete all
                await axios.delete('https://api.student.youelite.net/api/activities', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    }
                });
            }

            setMessage('Activities updated successfully');
        } catch (error) {
            console.error('Failed to update activities:', error);

            if (error.response) {
                console.error('Error response data:', error.response.data);
                setMessage(`Failed to update activities: ${error.response.data.message || error.response.statusText}`);
            } else {
                setMessage('Failed to update activities: An unknown error occurred.');
            }
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title>Activities</Card.Title>
                <Card.Text>Manage your extracurricular activities. To ensure precise analyses, you should report the activities as they are written in the CommonApp</Card.Text>
            </Card.Header>
            <Card.Body>
                <Form onSubmit={handleSubmit}>
                    <Row className="mb-3">
                        <Col md="12">
                            <Form.Group controlId="has_activities">
                                <Form.Label>Do you have activities to report? (max. 10)</Form.Label>
                                <Form.Control
                                    as="select"
                                    name="has_activities"
                                    value={formData.has_activities}
                                    onChange={(e) => {
                                        handleChange(e);
                                        if (e.target.value === 'No') {
                                            setFormData((prevData) => ({
                                                ...prevData,
                                                activities: [{ id: null, name: '', strength: null, description: '' }]
                                            }));
                                        }
                                    }}
                                >
                                    <option value="No">No</option>
                                    <option value="Yes">Yes</option>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    {formData.has_activities === 'Yes' && formData.activities.map((activity, index) => (
                        <Row className="mb-3 align-items-end" key={index}>
                            <Col md="4">
                                <Form.Group controlId={`activity_name_${index}`}>
                                    <Form.Label>Activity {index + 1} Name</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={activity.name || ''}
                                        onChange={(e) => handleActivityChange(index, e)}
                                        placeholder="Enter activity name"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md="2">
                                <Form.Group controlId={`activity_strength_${index}`}>
                                    <Form.Label>Strength (5 highest)</Form.Label>
                                    <Select
                                        name="strength"
                                        value={numberOptions.find(option => option.value === activity.strength) || null}
                                        onChange={(selectedOption) => handleStrengthChange(index, selectedOption)}
                                        options={numberOptions}
                                        placeholder="Select strength"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md="4">
                                <Form.Group controlId={`activity_description_${index}`}>
                                    <Form.Label>Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="description"
                                        value={activity.description || ''}
                                        onChange={(e) => handleActivityChange(index, e)}
                                        placeholder="Enter description"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md="2" className="d-flex align-items-center justify-content-center">
                                <Button
                                    variant="danger"
                                    onClick={() => removeActivity(index)}
                                >
                                    Remove
                                </Button>
                            </Col>
                        </Row>
                    ))}
                    {formData.has_activities === 'Yes' && formData.activities.length < 10 && (
                        <Row className="mb-3">
                            <Col className="text-end">
                                <Button variant="primary" onClick={addActivity}>
                                    Add Another Activity
                                </Button>
                            </Col>
                        </Row>
                    )}
                    <Button variant="primary" type="submit" className="me-2">Save Changes</Button>
                </Form>
                {message && <p className="mt-3">{message}</p>}
            </Card.Body>
        </Card>
    );
};





const PackagesTab = () => {
    const [loading, setLoading] = useState(true);
    const [message, setMessage] = useState('');
    const [currentPlan, setCurrentPlan] = useState(null);


    useEffect(() => {
        // Fetch subscription data from localStorage and set current plan based on user_type and active_free_trial
        const fetchPlans = async () => {
            try {

                // Fetch user details from localStorage
                const user = JSON.parse(localStorage.getItem('user'));

                if (user) {
                    const { user_type, active_free_trial } = user;

                    // Determine current plan based on user_type and active_free_trial
                    if (user_type === 1) {
                        setCurrentPlan('Free Plan');
                    } else if (user_type === 2 && active_free_trial) {
                        setCurrentPlan('Premium Trial');
                    } else if (user_type === 2 && !active_free_trial) {
                        setCurrentPlan('Premium Plan');
                    }
                } else {
                    setMessage('No user data found');
                }

            } catch (error) {
                console.error('Failed to fetch subscription data:', error);
                setMessage('Failed to load subscription data');
            } finally {
                setLoading(false);
            }
        };

        fetchPlans();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <Card>
            <Card.Header>
                <Card.Title>Manage Your Subscription</Card.Title>
                <Card.Text>View and manage your current subscription plan and billing information.</Card.Text>
            </Card.Header>
            <Card.Body>
                <Row className="mb-3">
                    <Col md="12">
                        <h5>Current Plan</h5>
                        {currentPlan ? (
                            <p>Your current plan is: <strong>{currentPlan}</strong></p>
                        ) : (
                            <p>No active subscription plan found.</p>
                        )}
                    </Col>
                </Row>


                <Row className="mb-3">
                    <Col md="12">
                        {/* Add the Manage Subscription Button */}
                        <ManageSubscriptionButton />
                    </Col>
                </Row>

                {message && <p className="text-danger mt-3">{message}</p>}
            </Card.Body>
        </Card>
    );
};



const ProfileTabs = {
    ProfileTab,
    EducationTab,
    ActivitiesTab,
    PackagesTab,
    SettingsTab
};

export default ProfileTabs;
