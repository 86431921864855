import React, { useEffect, useState } from "react";
import {
  Card,
  Row,
  Col,
  FormControl,
  InputGroup,
  Button,
  Dropdown,
  Modal,
  Spinner,
  Tooltip,
  OverlayTrigger,
  ProgressBar,
} from "react-bootstrap";
import { FaStar, FaEdit, FaPlus, FaTimes } from 'react-icons/fa';
import ReactApexChart from 'react-apexcharts';
import debounce from "lodash.debounce";
import Header from "../layouts/Header";
import EmojiPicker from 'emoji-picker-react';
import Footer from "../layouts/Footer";
import "../assets/css/CourseList.css"; // Custom CSS for the Courses Page

const CoursesPage = () => {
  useEffect(() => {
    document.title = "Courses | YouElite";
  }, []);

  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentCourse, setCurrentCourse] = useState(null);
  const [editedCourseTitle, setEditedCourseTitle] = useState("");
  const [editedCourseIcon, setEditedCourseIcon] = useState("");
  const maxCoursesToDisplay = 5;
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);

  const handleEmojiClick = (emoji) => {
    setEditedCourseIcon(emoji.emoji);
    setShowEmojiPicker(false); // Close picker after selection
  };

  const toggleEmojiPicker = () => {
    setShowEmojiPicker((prevState) => !prevState); // Toggle picker visibility
  };

  const closeEmojiPicker = () => {
    setShowEmojiPicker(false);
  };

  // Placeholder data for courses
  useEffect(() => {
    // Simulate fetching data
    const placeholderCourses = [
      {
        id: 1,
        title: "AP Chemistry",
        icon: "🧪",
        mastery: true,
        progress: 100,
        stats: {
          accuracy: 100,
          topicsCompleted: "30/30",
          lastActivity: "Today",
        },
      },
      {
        id: 2,
        title: "Geometry",
        icon: "📐",
        mastery: false,
        progress: 70,
        stats: {
          accuracy: 85,
          topicsCompleted: "14/20",
          lastActivity: "5 days ago",
        },
      },
      // Add more placeholder courses as needed
    ];
    setCourses(placeholderCourses);
    setLoading(false);
  }, []);

  const fetchSuggestions = debounce(async (query) => {
    // Implement your suggestion fetching logic here
    // For now, we'll use placeholder suggestions
    if (!query) {
      setSuggestions([]);
      return;
    }

    const placeholderSuggestions = [
      { id: 3, title: "Biology", icon: "🧬" },
      { id: 4, title: "Physics", icon: "🔭" },
      // Add more suggestions as needed
    ];

    setSuggestions(
      placeholderSuggestions.filter((course) =>
        course.title.toLowerCase().includes(query.toLowerCase())
      )
    );
  }, 300);

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    fetchSuggestions(query);
    setShowSuggestions(true);
  };

  const handleSearchBlur = () => {
    setTimeout(() => {
      setShowSuggestions(false);
    }, 200);
  };

  const handleSearchFocus = () => {
    if (suggestions.length > 0) {
      setShowSuggestions(true);
    }
  };

  const handleAddCourse = (course) => {
    if (courses.some((existingCourse) => existingCourse.id === course.id)) {
      console.warn("Course is already in the list");
      return;
    }
    setCourses([...courses, { 
      ...course, 
      mastery: false, 
      progress: 0,
      stats: {
        accuracy: 0,
        topicsCompleted: "0/0",
        lastActivity: "Never",
      },
    }]);
    setShowSuggestions(false);
  };
  

  const handleEditCourse = (course) => {
    setCurrentCourse(course);
    setEditedCourseTitle(course.title);
    setEditedCourseIcon(course.icon);
    setShowEditModal(true);
  };

  const handleSaveCourseChanges = () => {
    setCourses((prevCourses) =>
      prevCourses.map((course) =>
        course.id === currentCourse.id
          ? { ...course, title: editedCourseTitle, icon: editedCourseIcon }
          : course
      )
    );
    setShowEditModal(false);
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setCurrentCourse(null);
    setEditedCourseTitle("");
    setEditedCourseIcon("");
  };

  const renderTooltip = (props, stats) => (
    <Tooltip id="button-tooltip" {...props}>
      <div>
        <p>Accuracy: {stats.accuracy}%</p>
        <p>Topics Completed: {stats.topicsCompleted}</p>
        <p>Last Activity: {stats.lastActivity}</p>
      </div>
    </Tooltip>
  );

  const mobileStyles = {
    mainTitle: {
      textAlign: "center",
      marginBottom: "20px",
    },
    inputGroup: {
      marginBottom: "20px",
    },
    summary: {
      marginBottom: "20px",
    },
  };

  // Chart data for the summary side


  const accuracyChartOptions = {
    chart: {
      type: 'bar',
      height: 250, // Reduce the height to make the graph smaller
      toolbar: {
        show: false, // Disable the toolbar (removes the burger menu)
      },
    },
    title: {
      text: 'Overall Accuracy',
      align: 'center',
    },
    xaxis: {
      categories: courses.slice(0, maxCoursesToDisplay).map(course => course.title),
    },
    yaxis: {
      title: {
        text: 'Accuracy (%)',
      },
    },
  };

  const accuracyChartSeries = [
    {
      name: 'Accuracy',
      data: courses.slice(0, maxCoursesToDisplay).map(course => course.stats.accuracy),
    },
  ];
  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="content-wrapper">
          <Row className="mb-4">
            <Col>
              <h4 className="main-title mb-0">My Courses</h4>
            </Col>
          </Row>

          <Row className="d-flex flex-column-reverse flex-md-row">
            <Col md={8}>
              <Row className="mb-4" style={mobileStyles.inputGroup}>
                <Col>
                  <div style={{ position: "relative" }}>
                    <InputGroup>
                      <FormControl
                        placeholder="Search for a course"
                        value={searchQuery}
                        onChange={handleSearchChange}
                        onBlur={handleSearchBlur}
                        onFocus={handleSearchFocus}
                      />
                      <Button onClick={() => handleAddCourse({ title: searchQuery, icon: "📘", id: Date.now() })}>
                        <FaPlus />
                      </Button>
                    </InputGroup>
                    {showSuggestions && suggestions.length > 0 && (
                      <Dropdown.Menu
                        show
                        style={{
                          display: "block",
                          width: "100%",
                          maxHeight: "300px",
                          overflowY: "auto",
                          zIndex: 500,
                          position: "absolute",
                          top: "100%",
                          left: 0,
                        }}
                      >
                        {suggestions.map((suggestion, index) => (
                          <Dropdown.Item key={index} onClick={() => handleAddCourse(suggestion)}>
                            {suggestion.title}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    )}
                  </div>
                </Col>
              </Row>

              <Card className="card-course-list">
                <Card.Header>
                  <Card.Title as="h6">My Courses</Card.Title>
                </Card.Header>
                <Card.Body className="course-list-body">
                  {loading ? (
                    <div className="text-center">
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </Spinner>
                    </div>
                  ) : courses.length === 0 ? (
                    <p style={{ textAlign: "center" }}>Your course list is empty. Start adding courses!</p>
                  ) : (
                    <div className="course-grid">
                      {courses.map((course, index) => (
                        <OverlayTrigger
                          key={index}
                          placement="top"
                          delay={{ show: 200, hide: 100 }}
                          overlay={(props) => renderTooltip(props, course.stats)}
                        >
                          <Card
                            className={`course-card ${
                              course.mastery ? "mastery-course" : ""
                            }`}
                          >
                            <Card.Body>
                              <div className="course-header">
                                <h5>
                                  {course.icon} {course.title}
                                  {course.mastery && (
                                    <i className="ri-vip-crown-2-line mastery-crown"></i>
                                  )}

                                  {!course.mastery && (
                                    <span className="course-progress-text">
                                      {course.progress}%
                                    </span>
                                  )}
                                </h5>
                              </div>
                              <div className="course-actions mt-3">
                                <Button variant="primary" size="sm" className="me-2">
                                  Take Exam
                                </Button>
                                <Button
                                  variant="secondary"
                                  size="sm"
                                  onClick={() => handleEditCourse(course)}
                                >
                                  Edit Course
                                </Button>
                              </div>
                            </Card.Body>
                          </Card>
                        </OverlayTrigger>
                      ))}
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>

            <Col xs={12} md={4} style={mobileStyles.summary}>
              {/* Right Summary Panel */}
              <Card className="card-summary">
                <Card.Header>
                  <Card.Title as="h6">Summary</Card.Title>
                </Card.Header>
                <Card.Body>
                  {/* Global Performance Overview */}
                  <h6>Global Performance Overview</h6>
                  <p>
                    <strong>Best Performing Subject:</strong> Subject X
                  </p>
                  <p>
                    <strong>Worst Performing Subject:</strong> Subject X
                  </p>
                  <p>
                    <strong>Best Performing Topics:</strong> Topic A
                  </p>
                  <p>
                    <strong>Worst Performing Topics:</strong> Topic X
                  </p>
                  <hr />

                  {/* Chart for Course Completion */}
                  <Row className="mb-3">
                    <Col>
                      <h6>Course Completion</h6>
                      <ProgressBar>
                        <ProgressBar
                          variant="success"
                          now={(courses.filter(course => course.mastery).length / courses.length) * 100}
                          key={1}
                          label={`${Math.round((courses.filter(course => course.mastery).length / courses.length) * 100)}%`}
                        />
                        <ProgressBar
                          variant="warning"
                          now={(courses.filter(course => course.progress > 0 && !course.mastery).length / courses.length) * 100}
                          key={2}
                          label={`${Math.round((courses.filter(course => course.progress > 0 && !course.mastery).length / courses.length) * 100)}%`}
                        />
                        <ProgressBar
                          variant="danger"
                          now={(courses.filter(course => course.progress === 0).length / courses.length) * 100}
                          key={3}
                          label={`${Math.round((courses.filter(course => course.progress === 0).length / courses.length) * 100)}%`}
                        />
                      </ProgressBar>
                      <div className="mt-2">
                        <span className="badge bg-success me-2">
                          Completed: {Math.round((courses.filter(course => course.mastery).length / courses.length) * 100)}%
                        </span>
                        <span className="badge bg-warning me-2">
                          In Progress: {Math.round((courses.filter(course => course.progress > 0 && !course.mastery).length / courses.length) * 100)}%
                        </span>
                        <span className="badge bg-danger">
                          Not Started: {Math.round((courses.filter(course => course.progress === 0).length / courses.length) * 100)}%
                        </span>
                      </div>
                    </Col>
                  </Row>

                  <hr />

                  {/* Chart for Overall Accuracy */}
                  <div style={{ height: 250 }}>
                    <ReactApexChart
                      options={accuracyChartOptions}
                      series={accuracyChartSeries}
                      type="bar"
                      height="100%"
                    />
                  </div>
                  <hr />

                  {/* Activity Streaks or Study Milestones */}
                  <h6>Activity Streaks</h6>
                  <p>You have studied 5 days in a row!</p>
                  <p>You're close to mastering Geometry!</p>
                  <hr />

                  {/* Actionable Insights */}
                  <h6>Actionable Insights</h6>
                  <ul>
                    <li>Focus on reviewing notes before starting a new test.</li>
                    <li>Try timing yourself for better exam prep.</li>
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          {/* Edit Course Modal */}
          <Modal show={showEditModal} onHide={handleCloseEditModal} centered>
            <Modal.Header>
              <Modal.Title>Edit Course</Modal.Title>
              <Button variant="link" onClick={handleCloseEditModal}>
                <FaTimes />
              </Button>
            </Modal.Header>
            <Modal.Body>
              <FormControl
                className="mb-3"
                placeholder="Course Title"
                value={editedCourseTitle}
                onChange={(e) => setEditedCourseTitle(e.target.value)}
              />
              <FormControl
                className="mb-3"
                placeholder="Course Icon (e.g., 📘)"
                value={editedCourseIcon}
                onChange={(e) => setEditedCourseIcon(e.target.value)}
                onFocus={toggleEmojiPicker}
                onBlur={() => setTimeout(closeEmojiPicker, 200)} // Close with a delay to allow emoji selection
              />
              {showEmojiPicker && (
                <div
                  style={{
                    position: 'absolute',
                    top: '100%',
                    left: '10px',
                    zIndex: 1000,
                    width: '280px', // Set a fixed width for the picker
                    height: '300px', // Set a fixed height
                    overflow: 'hidden', // Ensure no extra scrolling
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)', // Shadow for visibility
                    borderRadius: '8px', // Rounded corners
                    backgroundColor: 'white', // Ensure background color consistency
                  }}
                >
                  <EmojiPicker
                    onEmojiClick={handleEmojiClick}
                    disableSearchBar={false} // Keeps search bar for usability
                    preload={true} // Preloads emojis to avoid delay
                    pickerStyle={{ width: '100%', height: '100%' }} // Make picker fit container
                  />
                </div>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleCloseEditModal}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSaveCourseChanges}>
                Save Changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
        <Footer className="footer" />
      </div>
    </React.Fragment>
  );
};

export default CoursesPage;
