// ProgressAnalytics.js

import React, { useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Container } from 'react-bootstrap';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import 'chart.js/auto';
import '../assets/css/ProgressAnalytics.css'; // Custom CSS

function ProgressAnalytics() {
  useEffect(() => {
    document.title = 'Progress Analytics | YouElite';
  }, []);

  const data = {
    labels: ['Test 1', 'Test 2', 'Test 3'],
    datasets: [
      {
        label: 'Score',
        data: [70, 80, 90],
        backgroundColor: 'rgba(52, 152, 219, 0.2)',
        borderColor: 'rgba(52, 152, 219, 1)',
        borderWidth: 1,
        fill: true,
      },
    ],
  };

  const options = {
    scales: {
      y: {
        beginAtZero: true,
        max: 100,
      },
    },
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="content-wrapper">
          <h4 className="main-title mb-4">Your Progress</h4>
          <Line data={data} options={options} />
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default ProgressAnalytics;
