import React, { useState } from 'react';
import { Card, Button, Form, ListGroup, Badge } from 'react-bootstrap';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

const JoinStudyGroupPage = () => {
  const [searchTerm, setSearchTerm] = useState('');

  const groups = [
    { id: 1, name: "SAT Prep Group", members: 25, category: "Test Prep" },
    { id: 2, name: "Essay Writing Workshop", members: 18, category: "Writing" },
    { id: 3, name: "College Interview Practice", members: 12, category: "Interview Prep" },
    { id: 4, name: "STEM Application Tips", members: 30, category: "Application Advice" },
  ];

  const handleSearch = (e) => {
    e.preventDefault();
    // Logic to search for groups
  };

  return (
    <div>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="content-wrapper">
          <h2 className="mb-4">Join a Study Group</h2>
          <Card className="mb-4">
            <Card.Body>
              <Form onSubmit={handleSearch}>
                <Form.Group className="mb-3">
                  <Form.Control 
                    type="text" 
                    placeholder="Search for study groups..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                </Form.Group>
                <Button variant="primary" type="submit">Search</Button>
              </Form>
            </Card.Body>
          </Card>

          <Card>
            <Card.Header>
              <h5>Available Study Groups</h5>
            </Card.Header>
            <ListGroup variant="flush">
              {groups.map(group => (
                <ListGroup.Item key={group.id}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h6>{group.name}</h6>
                      <Badge bg="secondary">{group.category}</Badge>
                    </div>
                    <div>
                      <Badge bg="primary" pill className="me-2">
                        {group.members} members
                      </Badge>
                      <Button variant="outline-primary" size="sm">Join</Button>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card>

          <Card className="mt-4">
            <Card.Header>
              <h5>Create Your Own Study Group</h5>
            </Card.Header>
            <Card.Body>
              <p>Can't find what you're looking for? Start your own study group!</p>
              <Button variant="success">Create a Study Group</Button>
            </Card.Body>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default JoinStudyGroupPage;
