import React, { useState, useEffect } from 'react';
import { Card, Button, Form, Modal, Table, Spinner, Alert } from 'react-bootstrap';
import axios from 'axios';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

const TagManager = () => {
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [formData, setFormData] = useState({ name: '' });
    const [editingTag, setEditingTag] = useState(null);

    useEffect(() => {
        fetchTags();
    }, []);

    const fetchTags = async () => {
        try {
            const response = await axios.get('/tags');
            setTags(response.data);
        } catch (error) {
            setError('Failed to load tags.');
        } finally {
            setLoading(false);
        }
    };

    const handleSubmit = async () => {
        try {
            const url = editingTag ? `/tags/${editingTag.id}` : '/tags';
            const method = editingTag ? 'put' : 'post';
            await axios[method](url, formData);
            fetchTags();
            setShowModal(false);
        } catch (error) {
            setError('Failed to save tag.');
        }
    };

    const handleDelete = async (id) => {
        if (!window.confirm('Are you sure you want to delete this tag?')) return;
        try {
            await axios.delete(`/tags/${id}`);
            fetchTags();
        } catch (error) {
            setError('Failed to delete tag.');
        }
    };

    const handleEdit = (tag) => {
        setFormData({ name: tag.name });
        setEditingTag(tag);
        setShowModal(true);
    };

    if (loading) return <div className="text-center"><Spinner animation="border" /></div>;

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <Card.Header>
                        <Card.Title>Manage Tags</Card.Title>
                        <Button variant="primary" onClick={() => setShowModal(true)}>Add Tag</Button>
                    </Card.Header>
                    <Card.Body>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {tags.map(tag => (
                                    <tr key={tag.id}>
                                        <td>{tag.name}</td>
                                        <td>
                                            <Button variant="warning" onClick={() => handleEdit(tag)}>Edit</Button>
                                            <Button variant="danger" onClick={() => handleDelete(tag.id)}>Delete</Button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>

                <Modal show={showModal} onHide={() => setShowModal(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{editingTag ? 'Edit Tag' : 'Add Tag'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="tagName">
                                <Form.Label>Tag Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="name"
                                    value={formData.name}
                                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                                />
                            </Form.Group>
                            <Button variant="primary" onClick={handleSubmit}>Save</Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default TagManager;
