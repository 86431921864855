import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Table, Form, Modal, Alert } from "react-bootstrap";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link } from "react-router-dom";
import axios from 'axios';

export default function TipManager() {
  const [tips, setTips] = useState([]);
  const [showTipModal, setShowTipModal] = useState(false);
  const [newTip, setNewTip] = useState({ title: '', description: '', body: '', category: '' });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [skin, setSkin] = useState(localStorage.getItem('skin-mode') || 'light');

  useEffect(() => {
    fetchTips();
  }, []);

  const fetchTips = async () => {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.get('/tips', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTips(response.data);
    } catch (error) {
      console.error('Error fetching tips:', error);
      setError('Failed to fetch tips. Please try again later.');
    }
  };

  const handleCreateTip = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const token = localStorage.getItem('auth_token');
      await axios.post('/tips', newTip, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setShowTipModal(false);
      setNewTip({ title: '', description: '', body: '', category: '' });
      setSuccess('Tip created successfully.');
      fetchTips();
    } catch (error) {
      console.error('Error creating tip:', error);
      setError('An error occurred while creating the tip. Please try again.');
    }
  };

  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link to="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Tip Manager</li>
            </ol>
            <h4 className="main-title mb-0">Tip Manager</h4>
          </div>
          <div className="mt-3 mt-md-0">
            <Button variant="primary" onClick={() => setShowTipModal(true)}>
              Create New Tip
            </Button>
          </div>
        </div>

        {error && <Alert variant="danger">{error}</Alert>}
        {success && <Alert variant="success">{success}</Alert>}

        <Card className="card-one">
          <Card.Header>
            <Card.Title as="h6">Tips Overview</Card.Title>
          </Card.Header>
          <Card.Body>
            <Table responsive className="table-tips mb-0">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Title</th>
                  <th>Category</th>
                  <th>Created At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {tips.map((tip) => (
                  <tr key={tip.id}>
                    <td><span className="ff-numerals">{tip.id}</span></td>
                    <td>{tip.title}</td>
                    <td>{tip.category}</td>
                    <td>{new Date(tip.created_at).toLocaleDateString()}</td>
                    <td>
                      <Button variant="outline-primary" size="sm">Edit</Button>{' '}
                      <Button variant="outline-danger" size="sm">Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>

        <Modal show={showTipModal} onHide={() => setShowTipModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Create New Tip</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form onSubmit={handleCreateTip}>
              <Form.Group className="mb-3">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  value={newTip.title}
                  onChange={(e) => setNewTip({...newTip, title: e.target.value})}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={2}
                  value={newTip.description}
                  onChange={(e) => setNewTip({...newTip, description: e.target.value})}
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Body</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={4}
                  value={newTip.body}
                  onChange={(e) => setNewTip({...newTip, body: e.target.value})}
                  required
                />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Category</Form.Label>
                <Form.Control
                  type="text"
                  value={newTip.category}
                  onChange={(e) => setNewTip({...newTip, category: e.target.value})}
                />
              </Form.Group>
              <Button variant="primary" type="submit">
                Create Tip
              </Button>
            </Form>
          </Modal.Body>
        </Modal>

        <Footer />
      </div>
    </React.Fragment>
  );
}