import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Button, Form, Modal, ListGroup, Spinner, Alert } from 'react-bootstrap';
import axios from 'axios';
import { DndContext, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, useSortable, arrayMove } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

const SortableItem = ({ article, handleEdit, handleDelete, id }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        marginBottom: '8px',
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <ListGroup.Item>
                <div className="d-flex justify-content-between">
                    <span>{article.title}</span>
                    <div>
                        <Button variant="warning" onClick={() => handleEdit(article)}>Edit</Button>
                        <Button variant="danger" onClick={() => handleDelete(article.id)}>Delete</Button>
                    </div>
                </div>
            </ListGroup.Item>
        </div>
    );
};

const ArticleManager = () => {
    const { subsectionId } = useParams();
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [subsectionName, setSubsectionName] = useState('');
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [formData, setFormData] = useState({ title: '', description: '', body: '' });
    const [editingArticle, setEditingArticle] = useState(null);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        })
    );

    useEffect(() => {
        fetchSubsectionName();
        fetchArticles();
    }, [subsectionId]);

    const fetchSubsectionName = async () => {
        try {
            const response = await axios.get(`/subsections/${subsectionId}`);
            setSubsectionName(response.data.title);
        } catch (error) {
            setError('Failed to load subsection name.');
        }
    };

    const fetchArticles = async () => {
        try {
            const response = await axios.get(`/subsections/${subsectionId}/articles`);
            setArticles(response.data);
        } catch (error) {
            setError('Failed to load articles.');
        } finally {
            setLoading(false);
        }
    };

    const handleReorder = async (newOrder) => {
        try {
            await axios.post('/articles/reorder', { articles: newOrder });
            fetchArticles(); // Reload the articles after reordering
        } catch (error) {
            setError('Failed to reorder articles.');
        }
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (!over) {
            console.warn('Item was dropped outside of a valid droppable area.');
            return;
        }

        if (active.id !== over.id) {
            const oldIndex = articles.findIndex((article) => article.id === active.id);
            const newIndex = articles.findIndex((article) => article.id === over.id);

            const newArticles = arrayMove(articles, oldIndex, newIndex);
            setArticles(newArticles);
            handleReorder(newArticles.map((article) => article.id));
        }
    };

    const handleSubmit = async () => {
        try {
            const url = editingArticle ? `/articles/${editingArticle.id}` : '/articles';
            const method = editingArticle ? 'put' : 'post';

            // Send the form data, line breaks will automatically be preserved as \n
            await axios[method](url, { ...formData, subsection_id: subsectionId });
            fetchArticles();
            handleCloseModals();
        } catch (error) {
            setError('Failed to save article.');
        }
    };

    const handleDelete = async (id) => {
        if (!window.confirm('Are you sure you want to delete this article?')) return;
        try {
            await axios.delete(`/articles/${id}`);
            fetchArticles();
        } catch (error) {
            setError('Failed to delete article.');
        }
    };

    const handleEdit = (article) => {
        setFormData({
            title: article.title,
            description: article.description,
            body: article.body
        });
        setEditingArticle(article);
        setShowEditModal(true);
    };

    const handleCloseModals = () => {
        setShowAddModal(false);
        setShowEditModal(false);
        setFormData({ title: '', description: '', body: '' });
        setEditingArticle(null);
    };

    if (loading) return <div className="text-center"><Spinner animation="border" /></div>;

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <Card.Header>
                        {/* Dynamically set the card title */}
                        <Card.Title>Manage {subsectionName}</Card.Title>
                        <Button variant="primary" onClick={() => setShowAddModal(true)}>Add Article</Button>
                    </Card.Header>
                    <Card.Body>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
                            <SortableContext items={articles} strategy={verticalListSortingStrategy}>
                                {articles.map((article) => (
                                    <SortableItem
                                        key={article.id.toString()}
                                        id={article.id.toString()}
                                        article={article}
                                        handleEdit={handleEdit}
                                        handleDelete={handleDelete}
                                    />
                                ))}
                            </SortableContext>
                        </DndContext>
                    </Card.Body>
                </Card>

                {/* Add Article Modal */}
                <Modal show={showAddModal} onHide={handleCloseModals}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Article</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="articleTitle">
                                <Form.Label>Article Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group controlId="articleDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="description"
                                    value={formData.description}
                                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group controlId="articleBody">
                                <Form.Label>Body</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    name="body"
                                    value={formData.body}
                                    onChange={(e) => setFormData({ ...formData, body: e.target.value })}
                                />
                            </Form.Group>
                            <Button variant="primary" onClick={handleSubmit}>Save</Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                {/* Edit Article Modal */}
                <Modal show={showEditModal} onHide={handleCloseModals}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Article</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="articleTitle">
                                <Form.Label>Article Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group controlId="articleDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="description"
                                    value={formData.description}
                                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group controlId="articleBody">
                                <Form.Label>Body</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    rows={5}
                                    name="body"
                                    value={formData.body}
                                    onChange={(e) => setFormData({ ...formData, body: e.target.value })}
                                />
                            </Form.Group>
                            <Button variant="primary" onClick={handleSubmit}>Save</Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default ArticleManager;
