import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Card, Button, Form, Modal, ListGroup, Spinner, Alert } from 'react-bootstrap';
import axios from 'axios';
import { DndContext, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, useSortable, arrayMove } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

const SortableItem = ({ subsection, handleEdit, handleDelete, id }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        marginBottom: '8px',
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <ListGroup.Item>
                <div className="d-flex justify-content-between">
                    {/* Link to navigate to articles */}
                    <Link to={`/subsections/${id}/articles`}>{subsection.title}</Link>
                    <div>
                        <Button variant="warning" onClick={() => handleEdit(subsection)}>Edit</Button>
                        <Button variant="danger" onClick={() => handleDelete(subsection.id)}>Delete</Button>
                    </div>
                </div>
            </ListGroup.Item>
        </div>
    );
};

const SubsectionManager = () => {
    const { sectionId } = useParams();
    const [subsections, setSubsections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [sectionName, setSectionName] = useState('');
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [formData, setFormData] = useState({ title: '', description: '' });
    const [editingSubsection, setEditingSubsection] = useState(null);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        })
    );

    useEffect(() => {
        fetchSectionName();
        fetchSubsections();
    }, [sectionId]);

    const fetchSectionName = async () => {
        try {
            const response = await axios.get(`/sections/${sectionId}`);
            setSectionName(response.data.title);
        } catch (error) {
            setError('Failed to load section name.');
        }
    };

    const fetchSubsections = async () => {
        try {
            const response = await axios.get(`/sections/${sectionId}/subsections`);
            setSubsections(response.data.subsections);
        } catch (error) {
            setError('Failed to load subsections.');
        } finally {
            setLoading(false);
        }
    };

    const handleReorder = async (newOrder) => {
        try {
            await axios.post('/subsections/reorder', { subsections: newOrder });
            fetchSubsections(); // Reload the subsections after reordering
        } catch (error) {
            setError('Failed to reorder subsections.');
        }
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (!over) {
            console.warn('Item was dropped outside of a valid droppable area.');
            return;
        }

        if (active.id !== over.id) {
            const oldIndex = subsections.findIndex((subsection) => subsection.id === active.id);
            const newIndex = subsections.findIndex((subsection) => subsection.id === over.id);

            const newSubsections = arrayMove(subsections, oldIndex, newIndex);
            setSubsections(newSubsections);
            handleReorder(newSubsections.map((subsection) => subsection.id));
        }
    };

    const handleSubmit = async () => {
        try {
            const url = editingSubsection ? `/subsections/${editingSubsection.id}` : '/subsections';
            const method = editingSubsection ? 'put' : 'post';
            await axios[method](url, { ...formData, section_id: sectionId });
            fetchSubsections();
            handleCloseModals();
        } catch (error) {
            setError('Failed to save subsection.');
        }
    };

    const handleDelete = async (id) => {
        if (!window.confirm('Are you sure you want to delete this subsection?')) return;
        try {
            await axios.delete(`/subsections/${id}`);
            fetchSubsections();
        } catch (error) {
            setError('Failed to delete subsection.');
        }
    };

    const handleEdit = (subsection) => {
        setFormData({ title: subsection.title, description: subsection.description });
        setEditingSubsection(subsection);
        setShowEditModal(true);
    };

    const handleCloseModals = () => {
        setShowAddModal(false);
        setShowEditModal(false);
        setFormData({ title: '', description: '' });
        setEditingSubsection(null);
    };

    if (loading) return <div className="text-center"><Spinner animation="border" /></div>;

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <Card.Header>
                        {/* Dynamically set the card title */}
                        <Card.Title>Manage "{sectionName}"</Card.Title>
                        <Button variant="primary" onClick={() => setShowAddModal(true)}>Add Subsection</Button>
                    </Card.Header>
                    <Card.Body>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
                            <SortableContext items={subsections} strategy={verticalListSortingStrategy}>
                                {subsections.map((subsection) => (
                                    <SortableItem
                                        key={subsection.id.toString()}
                                        id={subsection.id.toString()}
                                        subsection={subsection}
                                        handleEdit={handleEdit}
                                        handleDelete={handleDelete}
                                    />
                                ))}
                            </SortableContext>
                        </DndContext>
                    </Card.Body>
                </Card>

                {/* Add Subsection Modal */}
                <Modal show={showAddModal} onHide={handleCloseModals}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Subsection</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="subsectionTitle">
                                <Form.Label>Subsection Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group controlId="subsectionDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="description"
                                    value={formData.description}
                                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                />
                            </Form.Group>
                            <Button variant="primary" onClick={handleSubmit}>Save</Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                {/* Edit Subsection Modal */}
                <Modal show={showEditModal} onHide={handleCloseModals}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Subsection</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="subsectionTitle">
                                <Form.Label>Subsection Title</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group controlId="subsectionDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="description"
                                    value={formData.description}
                                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                />
                            </Form.Group>
                            <Button variant="primary" onClick={handleSubmit}>Save</Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default SubsectionManager;
