import {driver} from "driver.js";
import "driver.js/dist/driver.css";
import './tourCustomStyle.css'
import confetti from 'canvas-confetti';


export const startAppTour = (navigate) => {
    const driverObj = new driver({
      showProgress: true,
      popoverClass: 'driverjs-theme',
      steps: [
        { element: 'asdasdasdasd', popover: { title: 'Welcome, Future Genius!', description: 'Let’s kick things off with a bang and introduce you to your new academic HQ!', side: "bottom", align: 'center' }},
        { element: '#step1-tour', popover: { title: 'Your Personal Dashboard', description: 'This is your academic cockpit—track, plan, and conquer everything from here.', side: "bottom", align: 'center' }},
        { element: '#sidebar', popover: { title: 'Your Study Command Center', description: 'Here’s where all the magic happens—your go-to for everything.', side: "bottom", align: 'start' }},
        { element: '#grouped-sidebar', popover: { title: 'Your College Adventure Starts Here', description: 'Navigate your college journey with these powerful tools—finding your dream major, comparing colleges, and even scouting scholarships. We\'ve got you covered.', side: "bottom", align: 'start' }},
        { element: '#free-trial-badge', popover: { title: 'Free Trial—Because You Deserve It!', description: 'Enjoy all the perks, on us. No strings attached!', side: "left", align: 'start' }},
        { 
          element: '#final-step', 
          popover: { 
            title: 'Let’s Get You Accepted!', 
            description: 'You’re all set to start your college journey. Together, we’ll make those acceptance letters roll in!', 
            side: "top", 
            align: 'start' 
          },
          onNext: () => {
            launchConfetti();
          }
        },
      ],
      onDestroyStarted: () => {
        // Explicitly destroy the tour to close the card
        driverObj.destroy();
        launchConfetti();
        console.log("Tour destroyed (completed or canceled)");
        navigate("/"); // Redirect to homepage
      },
    });
  
    driverObj.drive();
  
    function launchConfetti() {
      confetti({
        particleCount: 150,
        spread: 140,
        origin: { x: 0.5, y: 0.5 },
        zIndex: 9999 // Ensure confetti appears above the overlay
      });
    }
  };