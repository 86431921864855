import React, { useState } from 'react';
import { Card, Button, ListGroup, Badge } from 'react-bootstrap';
import Avatar from '../components/Avatar';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';

const StudyBuddyMatchPage = () => {
  const [matches, setMatches] = useState([
    { id: 1, name: "Alex Johnson", subject: "SAT Math", method: "Video call", avatar: "path/to/avatar1.jpg", compatibility: 95 },
    { id: 2, name: "Sam Lee", subject: "SAT Math", method: "Text chat", avatar: "path/to/avatar2.jpg", compatibility: 88 },
    { id: 3, name: "Taylor Swift", subject: "SAT Math", method: "In-person", avatar: "path/to/avatar3.jpg", compatibility: 82 },
  ]);

  const handleConnect = (id) => {
    // Logic to connect with study buddy
    console.log(`Connecting with study buddy ${id}`);
  };

  return (
    <div>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="content-wrapper">
          <h2 className="mb-4">Your Study Buddy Matches</h2>
          <Card>
            <Card.Header>
              <h5>Matches for SAT Math - Video Call</h5>
            </Card.Header>
            <ListGroup variant="flush">
              {matches.map(match => (
                <ListGroup.Item key={match.id}>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <Avatar img={match.avatar} size="md" />
                      <div className="ms-3">
                        <h6 className="mb-0">{match.name}</h6>
                        <small>{match.subject} - {match.method}</small>
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <Badge bg="success" className="me-3">
                        {match.compatibility}% Match
                      </Badge>
                      <Button variant="primary" onClick={() => handleConnect(match.id)}>
                        Connect
                      </Button>
                    </div>
                  </div>
                </ListGroup.Item>
              ))}
            </ListGroup>
          </Card>
          <Card className="mt-4">
            <Card.Header>
              <h5>Study Buddy Tips</h5>
            </Card.Header>
            <Card.Body>
              <ul>
                <li>Set clear goals for your study sessions</li>
                <li>Agree on a schedule that works for both of you</li>
                <li>Be respectful of each other's time and learning style</li>
                <li>Use collaborative tools like shared documents or whiteboards</li>
                <li>Take breaks and stay motivated together</li>
              </ul>
            </Card.Body>
          </Card>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default StudyBuddyMatchPage;
