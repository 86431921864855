import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, Col, Row, Button, Badge, Form, Modal, Nav } from "react-bootstrap";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import Avatar from "../components/Avatar";
import "../assets/css/SocialHome.css";
import axios from "axios";
import Friends from "../components/Friends";




export default function AcademicSocialHome() {


  useEffect(() => {
    document.title = "Socials | YouElite";
  }, []);


  const [activeTab, setActiveTab] = useState("feed");
  const [posts, setPosts] = useState([]);
  const [newPost, setNewPost] = useState({ title: "", content: "", type: "" });
  const [studyGroups, setStudyGroups] = useState([]);
  const [studyBuddyRequest, setStudyBuddyRequest] = useState({ subject: "", study_method: "" });
  const [showModal, setShowModal] = useState(false);
  const [showJoinModal, setShowJoinModal] = useState(false);
  const [hasFriends, setHasFriends] = useState(false);
  const [newStudyGroup, setNewStudyGroup] = useState({
    name: "",
    description: "",
    category: "",
    next_meeting: ""
  });
  const [joinGroupId, setJoinGroupId] = useState('');

  useEffect(() => {
    fetchPosts();
    fetchFilteredStudyGroups();
  }, []);

  const fetchPosts = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get("/posts", {
        headers: { Authorization: `Bearer ${token}` }
      });
      
      const postsData = response.data;

      if (postsData.length > 0) {
        setPosts(postsData);
        setHasFriends(true);
      } else {
        setHasFriends(false);
      }
    } catch (error) {
      console.error("Error fetching posts:", error);
    }
  };

  const fetchFilteredStudyGroups = async () => {
    try {
      const token = localStorage.getItem("authToken");
      const response = await axios.get("/filtered-study-groups", {
        headers: { Authorization: `Bearer ${token}` }
      });
      setStudyGroups(response.data);
    } catch (error) {
      console.error("Error fetching study groups:", error);
    }
  };

  const handlePostSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("authToken");
      await axios.post("/posts", newPost, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setNewPost({ title: "", content: "", type: "" });
      fetchPosts();
    } catch (error) {
      console.error("Error creating post:", error);
    }
  };

  const handleStudyBuddyRequest = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("authToken");
      await axios.post("/study-buddy/request", studyBuddyRequest, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setStudyBuddyRequest({ subject: "", study_method: "" });
    } catch (error) {
      console.error("Error creating study buddy request:", error);
    }
  };

  const handleStudyGroupSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("authToken");
      await axios.post("/study-groups", newStudyGroup, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setShowModal(false);
      setNewStudyGroup({ name: "", description: "", category: "", next_meeting: "" });
      fetchFilteredStudyGroups();
    } catch (error) {
      console.error("Error creating study group:", error);
    }
  };

  const handleJoinStudyGroup = async () => {
    if (!joinGroupId) return;
    try {
      const token = localStorage.getItem("authToken");
      await axios.post(`/study-groups/${joinGroupId}/join`, null, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setShowJoinModal(false);
      setJoinGroupId('');
      alert('Request to join sent successfully!');
      fetchFilteredStudyGroups();
    } catch (error) {
      console.error("Error joining study group:", error);
      alert('Failed to send request. Please try again.');
    }
  };


  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="content-wrapper">
          <Row className="g-5">
            <Col xl={8}>
              <h4 className="academic-social-home-title mb-4">Social Hub</h4>
              <div className="academic-social-home-custom-tabs mb-4">
                <button
                  className={`academic-social-home-tab-button ${activeTab === 'feed' ? 'active' : ''}`}
                  onClick={() => setActiveTab('feed')}
                >
                  <i className="ri-share-circle-line"></i> Community Feed
                </button>
                <button
                  className={`academic-social-home-tab-button ${activeTab === 'collaborate' ? 'active' : ''}`}
                  onClick={() => setActiveTab('collaborate')}
                >
                  <i className="ri-team-line"></i> Collaborate
                </button>
                <button
                  className={`academic-social-home-tab-button ${activeTab === 'challenges' ? 'active' : ''}`}
                  onClick={() => setActiveTab('challenges')}
                >
                  <i className="ri-trophy-line"></i> Academic Challenges
                </button>
              </div>

              {activeTab === 'feed' && (
                <>
                  {/* Write a Post Section */}
                  <Card className="academic-social-home-card-post mb-4">
                    <Card.Header>
                      <Card.Title>Share with the Community</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      <Form onSubmit={handlePostSubmit}>
                        <Form.Group className="mb-3">
                          <Form.Control
                            type="text"
                            placeholder="Title"
                            value={newPost.title}
                            onChange={(e) => setNewPost({...newPost, title: e.target.value})}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Share study tips, resources, or questions..."
                            value={newPost.content}
                            onChange={(e) => setNewPost({...newPost, content: e.target.value})}
                          />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Select
                            value={newPost.type}
                            onChange={(e) => setNewPost({...newPost, type: e.target.value})}
                          >
                            <option>Select content type</option>
                            <option value="Study Tips">Study Tips</option>
                            <option value="Resource Sharing">Resource Sharing</option>
                            <option value="Question">Question</option>
                            <option value="Event">Event</option>
                          </Form.Select>
                        </Form.Group>
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <Button variant="outline-primary" className="me-2"><i className="ri-file-line"></i> Attach File</Button>
                            <Button variant="outline-primary"><i className="ri-link"></i> Add Link</Button>
                          </div>
                          <Button variant="primary" type="submit">Post</Button>
                        </div>
                      </Form>
                    </Card.Body>
                  </Card>

                  {/* Posts Section */}
                  {hasFriends ? (
                    posts.length > 0 ? (
                      posts.map((post) => (
                        <Card className="academic-social-home-card-post mb-4" key={post.id}>
                          <Card.Body>
                            <div className="d-flex justify-content-between align-items-center mb-3">
                              <div className="d-flex align-items-center">
                                <Link to={`/user/${post.author.id}`} className="me-2">
                                  <Avatar img={post.author.avatar} />
                                </Link>
                                <div>
                                  <h6 className="mb-0">{post.author.name}</h6>
                                  <small className="text-muted">{post.created_at}</small>
                                </div>
                              </div>
                              <Badge bg={post.type === "Writing Tips" ? "info" : post.type === "Study Group" ? "warning" : "success"}>
                                {post.type}
                              </Badge>
                            </div>
                            <h5 className="card-title">{post.title}</h5>
                            <p className="card-text">{post.content}</p>
                          </Card.Body>
                          <Card.Footer>
                            <Nav className="justify-content-between">
                              <div>
                                <Button variant="outline-primary" size="sm" className="me-2">
                                  <i className="ri-arrow-up-s-line"></i> {post.upvotes}
                                </Button>
                                <Button variant="outline-secondary" size="sm" className="me-2">
                                  <i className="ri-chat-1-line"></i> {post.comments_count} Comments
                                </Button>
                              </div>
                              <div>
                                <Button variant="outline-secondary" size="sm">
                                  <i className="ri-share-forward-line"></i> Share
                                </Button>
                              </div>
                            </Nav>
                          </Card.Footer>
                        </Card>
                      ))
                    ) : (
                      <Card className="academic-social-home-card-post mb-4">
                        <Card.Body>
                          <h5 className="card-title">Nothing to show here</h5>
                          <p className="card-text">There are no posts from your friends at the moment.</p>
                        </Card.Body>
                      </Card>
                    )
                  ) : (
                    <Card className="academic-social-home-card-post mb-4">
                      <Card.Body>
                        <h5 className="card-title">Nothing to show here!</h5>
                      </Card.Body>
                    </Card>
                  )}
                </>
              )}


              {activeTab === 'collaborate' && (
                <Card>
                  <Card.Header>
                    <Card.Title>Collaboration Opportunities</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <ul className="list-unstyled">
                      <li className="academic-social-home-collaboration-item">
                        <h6>Group Essay Review</h6>
                        <p>Join a peer review session for college application essays.</p>
                        <Button variant="outline-primary" className='mb-3' size="sm">Join Session</Button>
                      </li>
                      <li className="academic-social-home-collaboration-item">
                        <h6>Mock Interview Practice</h6>
                        <p>Practice your interview skills with fellow applicants.</p>
                        <Button variant="outline-primary" className='mb-3'size="sm">Schedule Practice</Button>
                      </li>
                      <li className="academic-social-home-collaboration-item">
                        <h6>Collaborative Research Project</h6>
                        <p>Work on a group research project to boost your application.</p>
                        <Button variant="outline-primary" size="sm">Explore Projects</Button>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              )}

              {activeTab === 'challenges' && (
                <Card>
                  <Card.Header>
                    <Card.Title>Academic Challenges</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <ul className="list-unstyled">
                      <li className="academic-social-home-challenge-item">
                        <h6>30-Day SAT Prep Challenge</h6>
                        <p>Boost your SAT score with daily practice and tips.</p>
                        <div className="progress mb-3">
                          <div className="progress-bar" role="progressbar" style={{width: "65%"}} aria-valuenow="65" aria-valuemin="0" aria-valuemax="100">65%</div>
                        </div>
                        <Button variant="outline-primary" className='mb-3'size="sm">Continue Challenge</Button>
                      </li>
                      <li className="academic-social-home-challenge-item">
                        <h6>College Essay Writing Sprint</h6>
                        <p>Write a compelling essay in just one week with guided prompts.</p>
                        <Button variant="outline-primary" className='mb-3'size="sm">Start Challenge</Button>
                      </li>
                      <li className="academic-social-home-challenge-item">
                        <h6>STEM Project Showcase</h6>
                        <p>Develop and present a STEM project to highlight your skills.</p>
                        <Button variant="outline-primary" size="sm">Join Showcase</Button>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              )}
            </Col>
            <Col xl={4}>
              <Friends />
              
              <Card className="mb-4">
                <Card.Header>
                  <Card.Title>Study Groups</Card.Title>
                </Card.Header>
                <Card.Body>
                  <ul className="list-unstyled">
                    {studyGroups.map((group) => (
                      <li key={group.id} className="d-flex justify-content-between align-items-center mb-2">
                        <Link to={`/study-groups/${group.id}`}>{group.name}</Link>
                        <div className="d-flex align-items-center">
                          {group.is_member && <Badge bg="success" pill className="me-2">Member</Badge>}
                          {group.has_requested && <Badge bg="warning" pill className="me-2">Request Pending</Badge>}
                          {group.is_invited && <Badge bg="info" pill className="me-2">Invited</Badge>}
                          <Badge bg="primary" pill>{group.total_members} members</Badge>
                        </div>
                      </li>
                    ))}
                  </ul>

                  <div className="d-flex">
                    <Button 
                      variant="outline-primary" 
                      className="w-50 me-2"
                      onClick={() => setShowModal(true)}
                    >
                      Create Study Group
                    </Button>
                    <Button variant="outline-primary" className="w-50" onClick={() => setShowJoinModal(true)}>
                      Join a Study Group
                    </Button>
                  </div>
                </Card.Body>
              </Card>



              <Card className="mb-4">
                <Card.Header>
                  <Card.Title>Study Buddy Finder</Card.Title>
                </Card.Header>
                <Card.Body>
                  <Form onSubmit={handleStudyBuddyRequest}>
                    <Form.Group className="mb-3">
                      <Form.Label>I'm studying:</Form.Label>
                      <Form.Select
                        value={studyBuddyRequest.subject}
                        onChange={(e) => setStudyBuddyRequest({...studyBuddyRequest, subject: e.target.value})}
                      >
                        <option>Select subject</option>
                        <option value="SAT Math">SAT Math</option>
                        <option value="AP Biology">AP Biology</option>
                        <option value="US History">US History</option>
                        <option value="Essay Writing">Essay Writing</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Preferred study method:</Form.Label>
                      <Form.Select
                        value={studyBuddyRequest.study_method}
                        onChange={(e) => setStudyBuddyRequest({...studyBuddyRequest, study_method: e.target.value})}
                      >
                        <option>Select method</option>
                        <option value="Video call">Video call</option>
                        <option value="Text chat">Text chat</option>
                        <option value="In-person">In-person (if nearby)</option>
                      </Form.Select>
                    </Form.Group>
                    <Button variant="primary" className="w-100" type="submit">Find Study Buddy</Button>
                  </Form>
                </Card.Body>
              </Card>



            </Col>
          </Row>
        </div>
        <Footer className="footer" />
      </div>

      {/* Modal for creating a new study group */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Create Study Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleStudyGroupSubmit}>
            <Form.Group className="mb-3">
              <Form.Label>Group Name</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter group name"
                value={newStudyGroup.name}
                onChange={(e) => setNewStudyGroup({ ...newStudyGroup, name: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Description</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                placeholder="Enter group description"
                value={newStudyGroup.description}
                onChange={(e) => setNewStudyGroup({ ...newStudyGroup, description: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Category</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter category"
                value={newStudyGroup.category}
                onChange={(e) => setNewStudyGroup({ ...newStudyGroup, category: e.target.value })}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Next Meeting (optional)</Form.Label>
              <Form.Control
                type="datetime-local"
                value={newStudyGroup.next_meeting}
                onChange={(e) => setNewStudyGroup({ ...newStudyGroup, next_meeting: e.target.value })}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Create
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Modal for joining a study group */}
      <Modal show={showJoinModal} onHide={() => setShowJoinModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Join a Study Group</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={(e) => { e.preventDefault(); handleJoinStudyGroup(); }}>
            <Form.Group className="mb-3">
              <Form.Label>Study Group ID</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter study group ID"
                value={joinGroupId}
                onChange={(e) => setJoinGroupId(e.target.value)}
                required
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Request to Join
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}
