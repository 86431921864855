import React, { useState, useEffect } from 'react';
import { Card, Button, Form, Modal, ListGroup, Spinner, Alert } from 'react-bootstrap';
import axios from 'axios';
import { DndContext, useSensor, useSensors, PointerSensor } from '@dnd-kit/core';
import {
    SortableContext,
    verticalListSortingStrategy,
    useSortable,
    arrayMove,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import { Link } from 'react-router-dom';

const SortableItem = ({ section, handleEdit, handleDelete, id }) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
        marginBottom: '8px',
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            <ListGroup.Item>
                <div className="d-flex justify-content-between">
                    {/* Link to navigate to subsections */}
                    <Link to={`/sections/${id}/subsections`}>{section.title}</Link>
                    <div>
                        <Button variant="warning" onClick={() => handleEdit(section)}>Edit</Button>
                        <Button variant="danger" onClick={() => handleDelete(section.id)}>Delete</Button>
                    </div>
                </div>
            </ListGroup.Item>
        </div>
    );
};

const SectionManager = () => {
    const [sections, setSections] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [formData, setFormData] = useState({ title: '', description: '' });
    const [editingSection, setEditingSection] = useState(null);

    const sensors = useSensors(
        useSensor(PointerSensor, {
            activationConstraint: {
                distance: 10,
            },
        })
    );

    useEffect(() => {
        fetchSections();
    }, []);

    const fetchSections = async () => {
        try {
            const response = await axios.get('/sections');
            setSections(response.data);
        } catch (error) {
            setError('Failed to load sections.');
        } finally {
            setLoading(false);
        }
    };

    const handleReorder = async (newOrder) => {
        try {
            await axios.post('/sections/reorder', { sections: newOrder });
            fetchSections(); // Reload the sections after reordering
        } catch (error) {
            setError('Failed to reorder sections.');
        }
    };

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (!over) {
            console.warn('Item was dropped outside of a valid droppable area.');
            return;
        }

        if (active.id !== over.id) {
            const oldIndex = sections.findIndex((section) => section.id === active.id);
            const newIndex = sections.findIndex((section) => section.id === over.id);

            const newSections = arrayMove(sections, oldIndex, newIndex);
            setSections(newSections);
            handleReorder(newSections.map((section) => section.id));
        }
    };

    const handleSubmit = async () => {
        try {
            const url = editingSection ? `/sections/${editingSection.id}` : '/sections';
            const method = editingSection ? 'put' : 'post';
            await axios[method](url, formData);
            fetchSections();
            handleCloseModals();
        } catch (error) {
            setError('Failed to save section.');
        }
    };

    const handleDelete = async (id) => {
        if (!window.confirm('Are you sure you want to delete this section?')) return;
        try {
            await axios.delete(`/sections/${id}`);
            fetchSections();
        } catch (error) {
            setError('Failed to delete section.');
        }
    };

    const handleEdit = (section) => {
        setFormData({ title: section.title, description: section.description });
        setEditingSection(section);
        setShowEditModal(true);
    };

    const handleCloseModals = () => {
        setShowAddModal(false);
        setShowEditModal(false);
        setFormData({ title: '', description: '' });
        setEditingSection(null);
    };

    if (loading) return <div className="text-center"><Spinner animation="border" /></div>;

    return (
        <React.Fragment>
            <Header />
            <div className="main main-app p-3 p-lg-4">
                <Card>
                    <Card.Header>
                        <Card.Title>Manage Sections</Card.Title>
                        <Button variant="primary" onClick={() => setShowAddModal(true)}>Add Section</Button>
                    </Card.Header>
                    <Card.Body>
                        {error && <Alert variant="danger">{error}</Alert>}
                        <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
                            <SortableContext items={sections} strategy={verticalListSortingStrategy}>
                                {sections.map((section) => (
                                    <SortableItem
                                        key={section.id.toString()}
                                        id={section.id.toString()}
                                        section={section}
                                        handleEdit={handleEdit}
                                        handleDelete={handleDelete}
                                    />
                                ))}
                            </SortableContext>
                        </DndContext>
                    </Card.Body>
                </Card>

                {/* Add Section Modal */}
                <Modal show={showAddModal} onHide={handleCloseModals}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Section</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="sectionName">
                                <Form.Label>Section Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group controlId="sectionDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="description"
                                    value={formData.description}
                                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                />
                            </Form.Group>
                            <Button variant="primary" onClick={handleSubmit}>Save</Button>
                        </Form>
                    </Modal.Body>
                </Modal>

                {/* Edit Section Modal */}
                <Modal show={showEditModal} onHide={handleCloseModals}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Section</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group controlId="sectionName">
                                <Form.Label>Section Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="title"
                                    value={formData.title}
                                    onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                                />
                            </Form.Group>
                            <Form.Group controlId="sectionDescription">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="description"
                                    value={formData.description}
                                    onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                                />
                            </Form.Group>
                            <Button variant="primary" onClick={handleSubmit}>Save</Button>
                        </Form>
                    </Modal.Body>
                </Modal>
            </div>
            <Footer />
        </React.Fragment>
    );
};

export default SectionManager;
