import React, { useState, useEffect } from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { InlineMath } from 'react-katex';
import 'katex/dist/katex.min.css';

const FullScreenWrapper = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  padding: 20px;
`;

const QuestionTitle = styled(Card.Title)`
  font-size: 1.5em;
  font-weight: bold;
  text-align: center;
`;

const ContentWrapper = styled.div`
  max-width: 600px;
  width: 100%;
  text-align: center;
`;

const TimerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const OptionItem = styled(ListGroup.Item)`
  cursor: pointer;
  border-radius: 10px;
  margin: 5px 0;
  padding: 15px;
  text-align: left;
  background-color: ${({ active }) => (active ? '#7a7a7a' : '#d9d9d9')} !important;
  color: ${({ active }) => (active ? '#ffffff' : '#000000')} !important;
  border: 2px solid ${({ active }) => (active ? '#666666' : '#c4c4c4')} !important;
  font-size: 1.25em; /* Matches the question font size */

  ${({ active }) =>
    !active &&
    `
      &:hover {
        background-color: #cfcfcf;
      }
    `}
`;

const QuestionContent = styled(Card.Text)`
  font-size: 1.25em;
  text-align: center;
  margin-top: 10px; /* Adjust this value to control spacing */
`;



const NavigationButton = styled(Button)`
  border-radius: 10px;
  margin: 10px 5px;
  padding: 10px 20px;
`;

/* New Styled Components for the Circles */
const QuestionCirclesWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
`;

const QuestionCircle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${({ answered }) => (answered ? '#d3d3d3' : 'transparent')}; /* Light gray when answered */
  border: 2px solid #d3d3d3; /* Light gray border */
  color: #000000; /* Always black text */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
  cursor: pointer;
  font-size: 0.8em;
`;



const TestTakingPage = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [timeLeft, setTimeLeft] = useState(0);
  const [timerStarted, setTimerStarted] = useState(false);
  const navigate = useNavigate();
  const currentQuestion = questions[currentQuestionIndex];

  const renderContent = (content) => {
    const parts = content.split(/(\$[^$]+\$)/g);
    return parts.map((part, index) => {
      if (part.startsWith('$') && part.endsWith('$')) {
        const math = part.slice(1, -1); // Remove the dollar signs
        const unescapedMath = math.replace(/\\\\/g, '\\'); // Replace double backslashes with single backslashes
        return <InlineMath key={index} math={unescapedMath} />;
      } else {
        return <span key={index}>{part}</span>;
      }
    });
  };

  useEffect(() => {
    const placeholderQuestions = [
      {
        id: 1,
        question: 'What is the capital of France?',
        options: ['Berlin', 'London', 'Paris', 'Rome'],
      },
      {
        id: 2,
        question: 'What is 2 + 2?',
        options: ['3', '4', '5', '6'],
      },
      {
        id: 3,
        question: 'Solve for $x$: $x^2 - 4 = 0$. What is the value of $x$?',
        options: ['2', '-2', '$\\\\pm 2$', '0'],
      },
    ];
    setQuestions(placeholderQuestions);
    setTimeLeft(10 * 60);
    setTimerStarted(true);
  }, []);

  useEffect(() => {
    if (timeLeft <= 0 && timerStarted) {
      handleTimeOut();
      return;
    }

    if (timeLeft > 0) {
      const timerId = setInterval(() => {
        setTimeLeft((prevTimeLeft) => prevTimeLeft - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [timeLeft, timerStarted]);

  const handleOptionSelect = (optionIndex) => {
    setSelectedOptions({
      ...selectedOptions,
      [currentQuestionIndex]: optionIndex,
    });
  };

  const finishTest = () => {
    navigate('/test/1/result');
  };

  const handleTimeOut = () => {
    window.alert('Time has finished. You will now be redirected to your results.');
    finishTest();
  };
  

  return (
    <FullScreenWrapper>
      <ContentWrapper>
        {currentQuestion ? (
          <>
            <TimerHeader>
              <h4>
                Time Left: {Math.floor(timeLeft / 60)}:
                {('0' + (timeLeft % 60)).slice(-2)}
              </h4>
              <Button variant="danger" onClick={finishTest}>
                Finish Test
              </Button>
            </TimerHeader>

            <Card className="mt-2">
              <Card.Body>
                <QuestionTitle>
                  Question {currentQuestionIndex + 1}
                </QuestionTitle>
                <QuestionContent>
                  {renderContent(currentQuestion.question)}
                </QuestionContent>


                <ListGroup variant="flush">
                  {currentQuestion.options.map((option, index) => (
                    <OptionItem
                      key={index}
                      active={selectedOptions[currentQuestionIndex] === index}
                      onClick={() => handleOptionSelect(index)}
                    >
                      {renderContent(option)}
                    </OptionItem>
                  ))}
                </ListGroup>
              </Card.Body>
            </Card>

            <div className="mt-4 d-flex justify-content-between">
              <NavigationButton
                variant="secondary"
                disabled={currentQuestionIndex === 0}
                onClick={() => setCurrentQuestionIndex(currentQuestionIndex - 1)}
              >
                Previous
              </NavigationButton>
              <NavigationButton
                variant="secondary"
                disabled={currentQuestionIndex === questions.length - 1}
                onClick={() => setCurrentQuestionIndex(currentQuestionIndex + 1)}
              >
                Next
              </NavigationButton>
            </div>

            {/* Question Circles at the Bottom */}
            <QuestionCirclesWrapper>
              {questions.map((question, index) => (
                <QuestionCircle
                  key={index}
                  answered={selectedOptions.hasOwnProperty(index)}
                  onClick={() => setCurrentQuestionIndex(index)}
                >
                  {index + 1}
                </QuestionCircle>
              ))}
            </QuestionCirclesWrapper>
          </>
        ) : (
          <div>Loading...</div>
        )}
      </ContentWrapper>
    </FullScreenWrapper>
  );
};

export default TestTakingPage;
