import React, { useState, useRef, useEffect } from 'react';
// eslint-disable-next-line
import { Card, Col, Row, Nav, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import '../assets/css/SocialTabs.css'; 

// PostsTab Component
const PostsTab = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [postContent, setPostContent] = useState('');
  const textareaRef = useRef(null);
  const containerRef = useRef(null);

  const handleExpand = () => {
    setIsExpanded(true);
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  };

  const handleCancel = () => {
    setIsExpanded(false);
    setPostContent('');
  };

  const handlePost = () => {
    console.log('Posted:', postContent);
    setIsExpanded(false);
    setPostContent('');
  };

  const handleInput = (e) => {
    setPostContent(e.target.value);
    adjustTextareaHeight();
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      // Reset height to auto to calculate new height
      textareaRef.current.style.height = 'auto';
      // Set new height based on scroll height
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    if (isExpanded) {
      adjustTextareaHeight();
    }
  }, [isExpanded, postContent]);

  return (
    <div className={`post-bar-container mt-4 ${isExpanded ? 'expanded' : ''}`}>
      <div className="post-bar" onClick={handleExpand}>
        <div className="post-bar-item">
          <i className="ri-edit-2-line"></i>
          <div className="textarea-container" ref={containerRef}>
            <textarea
              ref={textareaRef}
              value={postContent}
              onChange={handleInput}
              placeholder={isExpanded ? "What's on your mind?" : "Share an update"}
              rows="1"
            />
          </div>
        </div>
        {!isExpanded && (
          <div className="post-actions">
            <Link to=""><i className="ri-image-line"></i></Link>
            <Link to=""><i className="ri-vidicon-line"></i></Link>
            <Link to=""><i className="ri-article-line"></i></Link>
          </div>
        )}
      </div>
      {isExpanded && (
        <div className="post-buttons">
          <Button variant="secondary" onClick={handleCancel}>Cancel</Button>
          <Button variant="primary" onClick={handlePost}>Post</Button>
        </div>
      )}
    </div>
  );
};









// InfoTab Component
const InfoTab = ({ user }) => (
  <Card className="card-post mt-4 info-tab">
    <Card.Header className="info-tab-header">
      <Card.Title>Personal Information</Card.Title>
    </Card.Header>
    <Card.Body className="info-tab-body">
      {/* Additional personal information fields */}
      <p><strong>High School Name:</strong> {user ? user.hs_name : "Loading..."}</p>
      <p><strong>Class Rank:</strong> {user ? user.class_rank : "Loading..."}</p>
      <p><strong>Class Rank Reporting:</strong> {user ? user.class_rank_reporting : "Loading..."}</p>
      <p><strong>Weighted GPA:</strong> {user ? user.cumulative_gpa_weighted : "Loading..."}</p>
      <p><strong>Unweighted GPA:</strong> {user ? user.cumulative_gpa_unweighted : "Loading..."}</p>
      <p><strong>SAT Score:</strong> {user ? user.sat_score : "Loading..."}</p>
      <p><strong>SAT Math Score:</strong> {user ? user.sat_math_score : "Loading..."}</p>
      <p><strong>SAT Reading & Writing Score:</strong> {user ? user.sat_reading_writing_score : "Loading..."}</p>
      <p><strong>ACT Cumulative Score:</strong> {user ? user.act_cumulative_score : "Loading..."}</p>
      <p><strong>ACT English Score:</strong> {user ? user.act_english_score : "Loading..."}</p>
      <p><strong>ACT Mathematics Score:</strong> {user ? user.act_mathematics_score : "Loading..."}</p>
      <p><strong>ACT Reading Score:</strong> {user ? user.act_reading_score : "Loading..."}</p>
      <p><strong>ACT Science Score:</strong> {user ? user.act_science_score : "Loading..."}</p>
    </Card.Body>
  </Card>
);



// ConnectionsTab Component
const ConnectionsTab = () => (
  <Card className="card-post mt-4">
    <Card.Header>
      <Card.Title>Connections</Card.Title>
    </Card.Header>
    <Card.Body>
      {/* Placeholder content for Connections */}
      <p>Connections content goes here...</p>
    </Card.Body>
  </Card>
);

export { PostsTab, InfoTab, ConnectionsTab };
