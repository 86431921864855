// TestResult.js

import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Container, ListGroup, Badge, Button, Collapse } from 'react-bootstrap';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import '../assets/css/TestResult.css'; // Make sure to update your CSS accordingly

function TestResult() {
  const { testId } = useParams();

  const [score, setScore] = useState(80); // Mock score
  const [questions, setQuestions] = useState([]);
  const [userAnswers, setUserAnswers] = useState({});
  const [openExplanations, setOpenExplanations] = useState({});
  const [courseName, setCourseName] = useState('Introduction to Science');

  useEffect(() => {
    document.title = 'Test Results | YouElite';
  }, []);

  useEffect(() => {
    // Mock data for questions and userAnswers
    const mockQuestions = [
      {
        id: 1,
        content: 'What is the atomic number of Helium?',
        answers: [
          { id: 1, content: '1', isCorrect: false },
          { id: 2, content: '2', isCorrect: true },
          { id: 3, content: '3', isCorrect: false },
          { id: 4, content: '4', isCorrect: false },
        ],
        explanations: [
          '1 is the atomic number of Hydrogen.',
          'Correct! Helium has an atomic number of 2.',
          '3 is the atomic number of Lithium.',
          '4 is the atomic number of Beryllium.',
        ],
      },
      {
        id: 2,
        content: 'What is the capital of France?',
        answers: [
          { id: 5, content: 'Berlin', isCorrect: false },
          { id: 6, content: 'Paris', isCorrect: true },
          { id: 7, content: 'Rome', isCorrect: false },
          { id: 8, content: 'Madrid', isCorrect: false },
        ],
        explanations: [
          'Berlin is the capital of Germany.',
          'Correct! Paris is the capital of France.',
          'Rome is the capital of Italy.',
          'Madrid is the capital of Spain.',
        ],
      },
      // Add more placeholder questions
      {
        id: 3,
        content: 'Which planet is known as the Red Planet?',
        answers: [
          { id: 9, content: 'Earth', isCorrect: false },
          { id: 10, content: 'Mars', isCorrect: true },
          { id: 11, content: 'Jupiter', isCorrect: false },
          { id: 12, content: 'Saturn', isCorrect: false },
        ],
        explanations: [
          'Earth is known as the Blue Planet.',
          'Correct! Mars is known as the Red Planet due to its reddish appearance.',
          'Jupiter is the largest planet but not the Red Planet.',
          'Saturn is known for its rings, not as the Red Planet.',
        ],
      },
      {
        id: 4,
        content: 'What is the largest ocean on Earth?',
        answers: [
          { id: 13, content: 'Atlantic Ocean', isCorrect: false },
          { id: 14, content: 'Indian Ocean', isCorrect: false },
          { id: 15, content: 'Pacific Ocean', isCorrect: true },
          { id: 16, content: 'Arctic Ocean', isCorrect: false },
        ],
        explanations: [
          'The Atlantic Ocean is the second-largest ocean.',
          'The Indian Ocean is the third-largest ocean.',
          'Correct! The Pacific Ocean is the largest ocean.',
          'The Arctic Ocean is the smallest and shallowest ocean.',
        ],
      },
      {
        id: 5,
        content: 'Who wrote the play "Romeo and Juliet"?',
        answers: [
          { id: 17, content: 'William Shakespeare', isCorrect: true },
          { id: 18, content: 'Charles Dickens', isCorrect: false },
          { id: 19, content: 'Leo Tolstoy', isCorrect: false },
          { id: 20, content: 'Mark Twain', isCorrect: false },
        ],
        explanations: [
          'Correct! William Shakespeare wrote "Romeo and Juliet".',
          'Charles Dickens wrote novels like "Oliver Twist" and "A Tale of Two Cities".',
          'Leo Tolstoy wrote "War and Peace".',
          'Mark Twain is known for "Adventures of Huckleberry Finn".',
        ],
      },
      {
        id: 6,
        content: 'What is the chemical symbol for Gold?',
        answers: [
          { id: 21, content: 'Ag', isCorrect: false },
          { id: 22, content: 'Au', isCorrect: true },
          { id: 23, content: 'Gd', isCorrect: false },
          { id: 24, content: 'Go', isCorrect: false },
        ],
        explanations: [
          'Ag is the symbol for Silver.',
          'Correct! Au is the chemical symbol for Gold.',
          'Gd is the symbol for Gadolinium.',
          'Go is not a chemical symbol.',
        ],
      },
    ];

    const mockUserAnswers = {
      1: 1, // User selected answer ID 1 for question ID 1 (Incorrect)
      2: 6, // Correct
      3: 10, // Correct
      4: 14, // Incorrect
      5: 17, // Correct
      6: 23, // Incorrect
    };

    setQuestions(mockQuestions);
    setUserAnswers(mockUserAnswers);
  }, []);

  const getUserAnswer = (questionId) => {
    const answerId = userAnswers[questionId];
    const question = questions.find((q) => q.id === questionId);
    const answer = question.answers.find((a) => a.id === answerId);
    return answer ? answer.content : 'No Answer';
  };

  const getCorrectAnswer = (question) => {
    const correctAnswer = question.answers.find((a) => a.isCorrect);
    return correctAnswer ? correctAnswer.content : 'No Correct Answer';
  };

  const isAnswerCorrect = (questionId) => {
    const question = questions.find((q) => q.id === questionId);
    const userAnswerId = userAnswers[questionId];
    const userAnswer = question.answers.find((a) => a.id === userAnswerId);
    return userAnswer ? userAnswer.isCorrect : false;
  };

  const toggleExplanation = (questionId) => {
    setOpenExplanations((prev) => ({
      ...prev,
      [questionId]: !prev[questionId],
    }));
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="content-wrapper">
          <h4 className="main-title mb-4">Test Results</h4>
          <h5>
            Your Score: <Badge bg="success">{score}%</Badge>
          </h5>
          <ListGroup className="mt-4">
            {questions.map((question) => {
              const correct = isAnswerCorrect(question.id);
              return (
                <ListGroup.Item
                  key={question.id}
                  className={`question-item ${correct ? 'correct' : 'incorrect'}`}
                >
                  <div className="d-flex">
                    <div className="icon me-2">
                      {correct ? (
                        <FaCheckCircle className="text-success" />
                      ) : (
                        <FaTimesCircle className="text-danger" />
                      )}
                    </div>
                    <div className="flex-grow-1">
                      <p>
                        <strong>{question.content}</strong>
                      </p>
                      <p>Your Answer: {getUserAnswer(question.id)}</p>
                      {!correct && <p>Correct Answer: {getCorrectAnswer(question)}</p>}
                      {!correct && (
                        <>
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => toggleExplanation(question.id)}
                            aria-controls={`explanation-${question.id}`}
                            aria-expanded={openExplanations[question.id]}
                            className="mt-1"
                          >
                            Understand
                          </Button>
                          <Collapse in={openExplanations[question.id]}>
                            <div id={`explanation-${question.id}`} className="mt-2">
                              <ul>
                                {question.answers.map((answer, index) => (
                                  <li key={answer.id}>
                                    <strong>{answer.content}:</strong> {question.explanations[index]}
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </Collapse>
                        </>
                      )}
                    </div>
                  </div>
                </ListGroup.Item>
              );
            })}
          </ListGroup>
          <div className="mt-4">
            <Link to="/courses" className="btn btn-primary me-2">
              Back to Courses
            </Link>
            <Link to={`/courses/${testId}`} className="btn btn-secondary">
              Back to {courseName}
            </Link>
          </div>
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default TestResult;
