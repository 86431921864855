// TestCreation.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Form, Button, Row, Col } from 'react-bootstrap';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import '../assets/css/TestCreation.css'; // Custom CSS

function TestCreation() {
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    document.title = 'Create Test | YouElite';
  }, []);

  const course = { id: id, name: 'AP Chemistry' };

  const tags = [
    { id: 1, name: 'Thermodynamics' },
    { id: 2, name: 'Chemical Bonding' },
    { id: 3, name: 'Stoichiometry' },
    // Add more tags as needed
  ];

  const [testOptions, setTestOptions] = useState({
    numberOfQuestions: 15,
    selectedTopics: [],
    includeIncorrect: false,
    isTimed: false,
    timeLimit: 30,
  });

  const handleOptionChange = (e) => {
    const { name, value, type, checked } = e.target;
    setTestOptions((prevOptions) => ({
      ...prevOptions,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleTopicSelection = (e) => {
    const options = e.target.options;
    const selectedTopics = [];
    for (let i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        selectedTopics.push(options[i].value);
      }
    }
    setTestOptions((prevOptions) => ({
      ...prevOptions,
      selectedTopics: selectedTopics,
    }));
  };

  const startTest = (e) => {
    e.preventDefault();
    // Mock test ID
    const testId = 123;
    // In a real app, you'd send testOptions to the backend and get a test ID
    navigate(`/test/${testId}`);
  };

  return (
    <React.Fragment>
      <Header />
      <div className="main main-app p-3 p-lg-4">
        <div className="content-wrapper">
          <h4 className="main-title mb-4">Create a New Test for {course.name}</h4>
          <Form onSubmit={startTest}>
            <Form.Group as={Row} className="mb-3">
              <Form.Label column sm={3}>
                Number of Questions:
              </Form.Label>
              <Col sm={9}>
                <Form.Select
                  name="numberOfQuestions"
                  value={testOptions.numberOfQuestions}
                  onChange={handleOptionChange}
                >
                  <option value="15">15</option>
                  <option value="30">30</option>
                  <option value="45">45</option>
                </Form.Select>
              </Col>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Include Topics:</Form.Label>
              <Form.Select multiple onChange={handleTopicSelection}>
                {tags.map((tag) => (
                  <option key={tag.id} value={tag.name}>
                    {tag.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Include Incorrect Questions"
                name="includeIncorrect"
                checked={testOptions.includeIncorrect}
                onChange={handleOptionChange}
              />
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Check
                type="checkbox"
                label="Timed Test"
                name="isTimed"
                checked={testOptions.isTimed}
                onChange={handleOptionChange}
              />
            </Form.Group>

            {testOptions.isTimed && (
              <Form.Group as={Row} className="mb-3">
                <Form.Label column sm={3}>
                  Time Limit (minutes):
                </Form.Label>
                <Col sm={9}>
                  <Form.Control
                    type="number"
                    name="timeLimit"
                    value={testOptions.timeLimit}
                    min="1"
                    onChange={handleOptionChange}
                  />
                </Col>
              </Form.Group>
            )}

            <Button variant="primary" type="submit">
              Start Test
            </Button>
          </Form>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default TestCreation;
