import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Nav, Table, ProgressBar, Form, Modal, Alert, Offcanvas } from "react-bootstrap";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import { Link } from "react-router-dom";
import { Bar } from 'react-chartjs-2';
import axios from 'axios';


export default function HelpdeskService() {
  
  // State for Tickets, Categories, Users, Modals, etc.
  const [tickets, setTickets] = useState([]);
  const [categories, setCategories] = useState([]);
  const [users, setUsers] = useState([]);
  const [showTicketModal, setShowTicketModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [newCategory, setNewCategory] = useState({ name: '', description: '' });
  const [newTicket, setNewTicket] = useState({ category_id: '', subject: '', description: '' });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [skin, setSkin] = useState(localStorage.getItem('skin-mode') || 'light');
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [userId, setUserId] = useState(localStorage.getItem('user_id'));
  const [ticketsThisMonth, setTicketsThisMonth] = useState(0);
  const [openTicketsCount, setOpenTicketsCount] = useState(0);
  const [resolutionRate, setResolutionRate] = useState(0);
  const [editTicket, setEditTicket] = useState({ status: '', assigned_to: '' });
  const [showRight, setShowRight] = useState(false);
  const [csUsers, setCsUsers] = useState([]);
  const [selectedCsUser, setSelectedCsUser] = useState(null);
  const [replies, setReplies] = useState([]); // Store replies for the selected ticket
  const [newReply, setNewReply] = useState(''); // Track the content of the new reply
  const [userName] = useState(localStorage.getItem('user_name'));
  const [ticketCountsByCategory, setTicketCountsByCategory] = useState([]);

  const handleShowRight = () => setShowRight(true);
  const handleCloseRight = () => setShowRight(false);

  useEffect(() => {
    fetchTickets();
    fetchCategories();
    fetchTicketsThisMonth(); 
    fetchOpenTicketsCount();
    fetchResolutionRate();
    fetchTicketCountsByCategory();
  }, []);

  const fetchTicketsThisMonth = async () => {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.get('/count-tickets-m', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTicketsThisMonth(response.data.tickets_this_month);
    } catch (error) {
      console.error('Error fetching tickets for this month:', error);
      setError('Failed to fetch ticket count for this month. Please try again later.');
    }
  };


  const fetchCsUsers = async () => {
    try {
        const token = localStorage.getItem('auth_token');
        const response = await axios.get('/cs-users', {
            headers: { Authorization: `Bearer ${token}` }
        });
        setCsUsers(response.data.users);
    } catch (error) {
        console.error('Error fetching CS users:', error);
        setError('Failed to fetch CS users. Please try again later.');
    }
  };


  const fetchTicketCountsByCategory = async () => {
    try {
        const token = localStorage.getItem('auth_token');
        const response = await axios.get('/monthly-counts-by-category', {
            headers: { Authorization: `Bearer ${token}` }
        });
        setTicketCountsByCategory(response.data.ticket_counts);
    } catch (error) {
        console.error('Error fetching ticket counts by category:', error);
        setError('Failed to fetch ticket counts by category. Please try again later.');
    }
  };



  const categoryLabels = ticketCountsByCategory.map(item => item.category_name);
  const ticketCounts = ticketCountsByCategory.map(item => item.count);
  




  const handleUpdateTicket = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(null);

    try {
      const token = localStorage.getItem('auth_token');
      await axios.put(`/tickets/${selectedTicket.id}`, editTicket, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setShowTicketModal(false);
      setSuccess('Ticket updated successfully.');
      fetchTickets();  // Re-fetch the tickets to see the update
    } catch (error) {
      console.error('Error updating ticket:', error);
      setError('Failed to update ticket. Please try again.');
    }
  };
  
  const handleAddReply = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.post(`/tickets/${selectedTicket.id}/replies`, {
        content: newReply,
        user_id: userId
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const newReplyWithUser = {
        ...response.data,
        user: {
          id: userId,
          name: userName
        }
      };

      setReplies(prevReplies => [...prevReplies, newReplyWithUser]);
      setNewReply('');  // Clear the reply input field
    } catch (error) {
      console.error('Error adding reply:', error);
      setError('Failed to add reply. Please try again later.');
    }
  };


  const fetchResolutionRate = async () => {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.get('/cs-monthly-resolution-rate', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setResolutionRate(response.data.resolution_rate.toFixed(2));  // Round to 2 decimal places
    } catch (error) {
      console.error('Error fetching resolution rate:', error);
      setError('Failed to fetch resolution rate. Please try again later.');
    }
  };
  





  const fetchOpenTicketsCount = async () => {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.get('/cs-open-tickets', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setOpenTicketsCount(response.data.tickets.length);  // Set the count of open tickets
      setTickets(response.data.tickets);  // Set the tickets data to the response
    } catch (error) {
      console.error('Error fetching open tickets:', error);
      setError('Failed to fetch open tickets. Please try again later.');
    }
  };


  const handleEditClick = (ticket) => {
    // Clear the current state to reset modal content and notifications
    setSelectedTicket(null);
    setEditTicket({ status: '', assigned_to: '' });
    setReplies([]);
    setNewReply('');
    setSuccess(null);  // Clear the success message
    setError(null);    // Clear the error message if necessary
  
    // Set the new ticket data after clearing the previous one
    setSelectedTicket(ticket);
    setEditTicket({
      status: ticket.status,
      assigned_to: ticket.assigned_to
    });
  
    // Fetch replies for the new ticket
    fetchReplies(ticket.id);
  
    // Show the modal
    setShowTicketModal(true);
  };
  

  const fetchTickets = async () => {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.get('/cs-open-tickets', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTickets(response.data.tickets);
    } catch (error) {
      console.error('Error fetching tickets:', error);
      setError('Failed to fetch tickets. Please try again later.');
    }
  };
  

  const fetchReplies = async (ticketId) => {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.get(`/tickets/${ticketId}/replies`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setReplies(response.data);
    } catch (error) {
      console.error('Error fetching replies:', error);
      setError('Failed to fetch replies. Please try again later.');
    }
  };



  
  const fetchCategories = async () => {
    try {
      const token = localStorage.getItem('auth_token');
      const response = await axios.get('/ticket-categories', {
        headers: { Authorization: `Bearer ${token}` }
      });
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
      setError('Failed to fetch categories. Please try again later.');
    }
  };



  const handleCreateTicket = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const token = localStorage.getItem('auth_token');
      await axios.post('/tickets', { ...newTicket, assigned_to: localStorage.getItem('user_id') }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setShowTicketModal(false);
      setNewTicket({ category_id: '', subject: '', description: '' });
      setSuccess('Ticket created successfully.');
      fetchTickets();
    } catch (error) {
      console.error('Error creating ticket:', error);
      setError('An error occurred while creating the ticket. Please try again.');
    }
  };

  const handleCreateCategory = async (e) => {
    e.preventDefault();
    setError(null);
    try {
      const token = localStorage.getItem('auth_token');
      await axios.post('/ticket-categories', newCategory, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setShowCategoryModal(false);
      setNewCategory({ name: '', description: '' });
      setSuccess('Category created successfully.');
      fetchCategories();
    } catch (error) {
      console.error('Error creating category:', error);
      setError('An error occurred while creating the category. Please try again.');
    }
  };


  const handleTransferClick = () => {
    setShowTicketModal(false); // Close the Edit Ticket modal
    fetchCsUsers();
    handleShowRight();
  };


  const handleSaveTransfer = async () => {
    if (!selectedCsUser) {
        setError('Please select a CS user to transfer the ticket.');
        return;
    }

    try {
        const token = localStorage.getItem('auth_token');
        await axios.put(`/tickets/${selectedTicket.id}`, { assigned_to: selectedCsUser }, {
            headers: { Authorization: `Bearer ${token}` }
        });
        setSuccess('Ticket transferred successfully.');
        fetchTickets();  // Refresh the ticket list
        handleCloseRight();
    } catch (error) {
        console.error('Error transferring ticket:', error);
        setError('Failed to transfer ticket. Please try again.');
    }
  };






  const dataBar1 = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [{
      data: [20, 60, 50, 45, 50, 60, 70, 40, 45, 35, 25, 30],
      backgroundColor: '#506fd9',
      barPercentage: 0.5
    }, {
      data: [10, 40, 30, 40, 60, 55, 45, 35, 30, 20, 15, 20],
      backgroundColor: '#85b6ff',
      barPercentage: 0.5
    }, {
      data: [8, 30, 40, 35, 40, 45, 35, 30, 25, 10, 20, 15],
      backgroundColor: '#e2e5ec',
      barPercentage: 0.5
    }]
  };

  const optionBar1 = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        max: 80,
        ticks: {
          color: '#a1aab3',
          font: {
            size: 10
          }
        },
        grid: {
          borderColor: '#e2e5ec',
          borderWidth: 1.5,
          color: 'rgba(65,80,95,.08)'
        }
      },
      x: {
        ticks: {
          color: '#313c47'
        },
        grid: {
          color: 'rgba(65,80,95,.08)'
        }
      }
    }
  };

  const dataBar2 = {
    labels: categoryLabels,
    datasets: [{
        data: ticketCounts,
        backgroundColor: ['#506fd9', '#85b6ff', '#33d685', '#ffc107', '#0dcaf0', '#ea4c89'], // Adjust colors as needed
        barPercentage: 0.5
    }]
  };

  const optionBar2 = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
        legend: {
            display: false
        }
    },
    scales: {
        x: {
            beginAtZero: true,
            max: Math.max(...ticketCounts) + 10, // Dynamically adjust based on the highest count
            grid: {
                borderColor: '#e2e5ec',
                color: '#f3f5f9'
            },
            ticks: {
                font: {
                    size: 11
                }
            }
        },
        y: {
            grid: {
                borderWidth: 0,
                color: '#f3f5f9'
            },
            ticks: {
                color: '#465463',
                font: {
                    size: 13
                }
            }
        }
    }
  };


  return (
    <React.Fragment>
      <Header onSkin={setSkin} />
      <div className="main main-app p-3 p-lg-4">
        <div className="d-md-flex align-items-center justify-content-between mb-4">
          <div>
            <ol className="breadcrumb fs-sm mb-1">
              <li className="breadcrumb-item"><Link href="#">Dashboard</Link></li>
              <li className="breadcrumb-item active" aria-current="page">Ticketing Dashboard</li>
            </ol>
            <h4 className="main-title mb-0">Welcome to Dashboard</h4>
          </div>
        </div>

        <Row className="g-3">
          {/* Cards for Quick Overview */}
          {[
            {
              "bg": "primary",
              "icon": "ri-user-voice-line",
              "value": openTicketsCount, // Use the new state here
              "percent": "",  // You can update this if needed
              "success": true,
              "label": "Current Support Requests",
              "agents": openTicketsCount // Use the new state here
            },
            {
              "bg": "primary",
              "icon": "ri-map-pin-user-line",
              "value": ticketsThisMonth, // Use the new state here
              "percent": "",
              "success": false,
              "label": "Tickets Assigned This Month",
              "agents": ticketsThisMonth // Use the new state here
            },
            {
              "bg": "primary",
              "icon": "ri-check-double-line",
              "value": `${resolutionRate}%`,  // Display the resolution rate
              "percent": "",
              "success": resolutionRate >= 50,  // Success based on rate (example: >= 50% is good)
              "label": "Monthly Ticket Resolution Rate",
              "agents": `${resolutionRate}%`
            }
          ].map((item, index) => (
            <Col sm="4" key={index}>
              <Card className="card-one">
                <Card.Body className="p-3">
                  <div className="d-flex d-sm-block d-xl-flex align-items-center">
                    <div className={"helpdesk-icon text-white bg-" + item.bg}><i className={item.icon}></i></div>
                    <div className="ms-3 ms-sm-0 ms-xl-3 mt-sm-3 mt-xl-0">
                      <h2 className="card-value d-flex align-items-baseline mb-0">{item.value} <small className={"text-" + (item.success ? "success" : "")}>{item.percent}</small></h2>
                      <label className="card-label fs-sm fw-medium mb-1">{item.label}</label>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          ))}
          <Col md="7" xl="8">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Current Ticket Status</Card.Title>
                <Nav className="nav-icon nav-icon-sm ms-auto">
                  <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                  <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                </Nav>
              </Card.Header>
              <Card.Body>
                <div className="chartjs-one">
                  <Bar data={dataBar1} options={optionBar1} />
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col md="5" xl="4">
            <Card className="carding-ticket-one">
                <Card.Header>
                    <Card.Title as="h6">Tickets By Category</Card.Title>
                    <Nav className="nav-icon nav-icon-sm ms-auto">
                        <Nav.Link href=""><i className="ri-refresh-line"></i></Nav.Link>
                        <Nav.Link href=""><i className="ri-more-2-fill"></i></Nav.Link>
                    </Nav>
                </Card.Header>
                <Card.Body>
                    {ticketCountsByCategory.length > 0 ? (
                        <div className="chartjs-two">
                            <Bar data={dataBar2} options={optionBar2} height={278} />
                        </div>
                    ) : (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '278px' }}>
                            <p>No tickets available for this month.</p>
                        </div>
                    )}
                </Card.Body>
            </Card>
          </Col>

          {/* Tickets Table */}
          <Col xs="12">
            <Card className="card-one">
              <Card.Header>
                <Card.Title as="h6">Tickets Overview</Card.Title>
              </Card.Header>
              <Card.Body>
                <Table responsive className="table-tickets mb-0">
                    <thead><tr><th>ID</th><th>User</th><th>Subject</th><th>Category</th><th>Status</th><th>Created At</th><th>Actions</th></tr></thead>
                    <tbody>
                        {tickets.map((ticket) => (
                            <tr key={ticket.id}>
                                <td><span className="ff-numerals">{ticket.id}</span></td>
                                <td>{`${ticket.user.name} ${ticket.user.surname}`}</td> {/* Display full name */}
                                <td>{ticket.subject}</td>
                                <td>{ticket.category.name}</td>
                                <td>
                                    <span className={`badge bg-${ticket.status === 'open' ? 'success' : ticket.status === 'in_progress' ? 'warning' : 'secondary'}`}>
                                        {ticket.status}
                                    </span>
                                </td>
                                <td>{new Date(ticket.created_at).toLocaleDateString()}</td>
                                <td>
                                    <Button variant="outline-primary" onClick={() => handleEditClick(ticket)}>Edit</Button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
              </Card.Body>


            </Card>
          </Col>

          {/* Modals for Creating Tickets and Categories */}
          <Modal show={showTicketModal} onHide={() => setShowTicketModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>Ticket Description</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}

              {selectedTicket?.subject && (
                <p className="mb-3"><strong>Ticket Title:</strong> {selectedTicket.subject}</p>
              )}      
              
              {selectedTicket?.description && (
                <p className="mb-3"><strong>Ticket Description:</strong> {selectedTicket.description}</p>
              )}

              <Form onSubmit={handleUpdateTicket}>
                <Form.Group className="mb-3">
                  <Form.Label>Status</Form.Label>
                  <Form.Select 
                    value={editTicket.status} 
                    onChange={(e) => setEditTicket({...editTicket, status: e.target.value})}
                    required
                  >
                    <option value="open">Open</option>
                    <option value="in_progress">In Progress</option>
                    <option value="closed">Closed</option>
                  </Form.Select>
                </Form.Group>

                {/* Update label to "Client's Name" */}
                <Form.Group className="mb-3">
                  <Form.Label>Client's Name</Form.Label>
                  <Form.Control
                    plaintext
                    readOnly
                    defaultValue={`${selectedTicket?.user?.name} ${selectedTicket?.user?.surname}`}
                    className="form-control-plaintext"
                    style={{ marginBottom: '10px', paddingTop: '2px' }}  
                  />
                  <Button variant="secondary" onClick={handleTransferClick}>Transfer</Button>
                  <Button variant="primary" type="submit">
                    Update Ticket
                  </Button>
                </Form.Group>
              </Form>

              <hr />

              <h5>Replies</h5>
              <div className="replies-section">
                {replies.length > 0 ? (
                  replies.map(reply => (
                    <div key={reply.id} className="mb-3 reply">
                      <p><strong>{reply.user?.name || 'Unknown User'}:</strong> {reply.content}</p>
                      <p className="text-muted" style={{ fontSize: '0.8em' }}>{new Date(reply.created_at).toLocaleString()}</p>
                      <hr />
                    </div>
                  ))
                ) : (
                  <p>No one replied.</p>
                )}

                <Form onSubmit={handleAddReply}>
                  <Form.Group className="mb-3">
                    <Form.Label>Add a Reply</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      value={newReply}
                      onChange={(e) => setNewReply(e.target.value)}
                      required
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Submit Reply
                  </Button>
                </Form>
              </div>
            </Modal.Body>
          </Modal>

          <Offcanvas show={showRight} onHide={handleCloseRight} placement="end">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Transfer Ticket</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <Form>
                    {csUsers.map((user) => (
                        <Form.Check 
                            type="radio" 
                            label={`${user.name} ${user.surname}`} 
                            name="csUser" 
                            key={user.id}
                            value={user.id}
                            checked={selectedCsUser === user.id}
                            onChange={(e) => setSelectedCsUser(parseInt(e.target.value))}
                        />
                    ))}
                    <Button variant="primary" onClick={handleSaveTransfer}>Save</Button>
                </Form>
            </Offcanvas.Body>
        </Offcanvas>

        </Row>

        <Footer />
      </div>
    </React.Fragment>
  );
}